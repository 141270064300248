import { useState, useEffect } from 'react';
import Card from '../cards/public/default';
import { CreatorCard } from '../cards/public/creator';
import { list as listAssets } from '../methods/list';
import { PublicListRoute } from '../dto/routeProps';
import { generator } from '../skeletons/generator';
import { useParams } from 'react-router-dom';


export const Index = (props: PublicListRoute) => {
    const [receivedAssetsResponse, setReceivedAssetsResponse] = useState<boolean>(false);
    const [list, setList] = useState<Array<any>>([]);
    const { category, relationship, account } = useParams();

    const getCategory = (): string => {
        if ( props.category )
            return props.category;
        if ( category )
            return category;
        if ( localStorage.getItem("homepageList") ) {
            return String(localStorage.getItem("homepageList"));
        }
        return "sales";
    }

    const getSubRoute = (): string => {
        if ( props.subroute )
            return props.subroute;
        if ( relationship )
            return relationship;
        if ( localStorage.getItem("homepageSubRoute") ) {
            return String(localStorage.getItem("homepageSubRoute"));
        }
        return "marketplace";
    }

    useEffect(() => {
        try {
            const fetch = async () => {
                if ( props.network ) {
                    setList([]);
                    setReceivedAssetsResponse(false);
                    await listAssets(
                        props.network.API_URL,
                        getCategory(),
                        props.network.CHAIN_ID,
                        getSubRoute() + ( account ? "/" + account : ""),
                        props.setError,
                        setList
                    );
                    setReceivedAssetsResponse(true);
                }
            }
            fetch();
        } catch (err) {
            props.setError("Internal error, try again later!");
        }
    }, [props.category]);



    return (
        <div className='row mb-5 ml-5 mr-5 justify-content-center text-center'>
            {
                props.canCreate && 
                <CreatorCard category={getCategory()} theme={props.theme} />
            }
            {
                receivedAssetsResponse ? (
                    <>
                        {
                            list.length > 0 ? (
                                <>
                                    {
                                        list.map((item: any, id: number) => 
                                            <Card key={id} item={item} category={getCategory()} />
                                        )
                                    }
                                </>
                            ) : (
                                <>
                                    {generator(9, props.theme)}
                                </>
                            )
                        }
                    </>
                ) : (
                    <>
                        {generator(9, props.theme)}
                    </>
                )
            }
        </div>
    );
}