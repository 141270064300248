import { httpSimpleGetRequest, IPublicNetwork } from "../../app/utility";


export interface IGetCreationPublicAssetsParams {
    network: IPublicNetwork,
    accounts: Array<string>,
    setError: Function,
    setRequestFinished: Function,
    setAssets: Function
}

export const getCreationPublicAssets = async (
    props: IGetCreationPublicAssetsParams
) => {

    try {

        const theAssets = await httpSimpleGetRequest(props.network.API_URL + '/' + props.network.CHAIN_ID + '/assets/' + props.accounts[0]);

        if ( theAssets ) {

            props.setRequestFinished(true);
            if ( theAssets.errorCode !== undefined && theAssets.errorMessage !== undefined ) {

                props.setError(theAssets.errorMessage);

                if ( theAssets.errorCode === null && theAssets.errorMessage === null ) {

                    let formattedAssets = [];
                    for ( let i = 0 , l = theAssets.entities.length ; i < l ; ++i ) {

                        formattedAssets.push({
                            ...theAssets.entities[i],
                            quantity: 0
                        });

                    }

                    props.setAssets(formattedAssets);

                }

            }

        }

    } catch (err) {
        props.setError('Internal error, try again later!');
    }

}