import { Theme } from '@mui/material/styles';
import { CreationItemLoader } from './creationItemLoader';

export const generator = (
    total: number, 
    theme: Theme,
    style?: object | undefined
): JSX.Element => {
    return (
        <>
            {
                [...Array(total)].map((x, i) => 
                    <CreationItemLoader theme={theme} key={i} style={style} />
                )
            }
        </>
    );
}