import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { listOne } from '../../common/methods/listOne';
import { BigNumber } from 'ethers';
import { toHex, toBN } from 'web3-utils';
import { AUCTIONS as PRIVATE_AUCTIONS } from '../../../contracts/private/typechain-types/Auctions.sol/AUCTIONS';
import { PrivateRoute } from '../../common/dto/routeProps';
import { IEvmPrivateAuction } from '../../common/dto/models/private/evmAuctions';


export const Index = (props: PrivateRoute) => {
    const optionButtonsStyle = { color: '#201F29' , borderColor: '#201F29' };
    const [item, setItem] = useState<IEvmPrivateAuction | null>(null);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        try {
            const fetch = async () => {
                if ( id && props.network ) {
                    await listOne(
                        props.network.API_URL,
                        props.category,
                        props.network.CHAIN_ID,
                        id,
                        props.setError,
                        setItem
                    );
                }
            }
            fetch();
        } catch (err) {
            props.setError('Internal error, try again later!');
        }
    }, [id, props.network]);


    const cancel = async () => {
        try {
            if ( item && props.network ) {
                (props.network.AUCTIONS as PRIVATE_AUCTIONS)
                .cancelAuction(BigNumber.from(item.tokenId));
                
                /*
                .on('receipt',() => {
                    setItem({
                        ...item,
                        status: 3
                    });
                    navigate(-1);
                }).on('error',(err: any) => console.error(Error(err).message));
                */

            }
        } catch (err) {
            console.error(err);
        }
    }

    const bid = async () => {
        try {
            if ( item && props.network ) {

                /*
                const totalToPay = toHex(toBN(item.price).add(toBN(item.tax)));
                (props.network.AUCTIONS as PRIVATE_AUCTIONS)
                .payForAuction(BigNumber.from(item.tokenId),totalToPay);
                */
               
                /*
                .on('receipt',() => {
                    setItem({
                        ...item,
                        winner: props.accounts[0]
                    });
                    props.setSuccess('Successfully placed bid!');
                }).on('error',(err: any) => console.error(Error(err).message));
                */

            }
        } catch (err) {
            console.error(err);
        }
    }

    const finish = async () => {
        try {
            if ( item && props.network ) {
                (props.network.AUCTIONS as PRIVATE_AUCTIONS)
                .finishAuction(BigNumber.from(item.tokenId));
                
                /*
                .send({
                    from: props.accounts[0]
                }).on('receipt',() => {
                    setItem({
                        ...item,
                        status: 2
                    });
                    navigate(-1);
                }).on('error',(err: any) => console.error(Error(err).message));
                */
               
            }
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <>
        </>
    );
}