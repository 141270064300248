import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import { useNavigate, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Blockies from 'react-blockies';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import { changeNetwork } from '../methods/changeNetwork';
import { synchronizeRootStateConnection } from '../methods/synchronizeRootStateConnection';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { useTheme } from '@mui/material/styles';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LoginIcon from '@mui/icons-material/Login';
import { metamaskAuth } from '../methods/metamaskAuth';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Dialog from '@mui/material/Dialog';
import { INetworks, IPrivateNetwork, IPublicNetwork, IRoute, ISubRoute } from '../../app/utility';
import { IRouteProps } from '../dto/routeProps';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const StyledsubRoutesMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 18,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    }
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
}));

export interface NavigationBarProps extends IRouteProps {
    network: IPublicNetwork | IPrivateNetwork | null,
    NETWORKS: INetworks,
    setRefresher: Function,
    setNetwork: Function,
    setAccounts: Function,
    setSigner: Function,
    setTheme: Function,
    homepageList: string, 
    setHomepageList: Function
}

export const NavigationBar = (props: NavigationBarProps) => {
    const NETWORKS = Object.values(props.NETWORKS);
    const theme = useTheme();
    const navigate = useNavigate();

    const [networkSelectModal, setNetworkSelectModal] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [homepageListanchorEl, setHomepageListanchorEl] = React.useState<null | HTMLElement>(null);
    const homepageListOpen = Boolean(homepageListanchorEl);
    const [subRoutes, setsubRoutes] = React.useState<Array<ISubRoute>>([]);

    const handleHomepageList = (selection: string) => {
        props.setHomepageList(selection);
        localStorage.setItem("homepageList", selection);
        setHomepageListanchorEl(null);
    }

    const handleHomepageClick = (event: React.MouseEvent<HTMLElement>) => {
        setHomepageListanchorEl(event.currentTarget);
      };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleHomepageClose = () => {
        setHomepageListanchorEl(null);
      };

    const addGame = () => {
        if ( props.network ) {
            navigate(props.network.ROUTES[5].PATH);
        }
    }

    const select = (network: IPublicNetwork | IPrivateNetwork) => {
        changeNetwork(network).then((chainId: string) => {
            synchronizeRootStateConnection({
                setNetwork: props.setNetwork,
                setRefresher: props.setRefresher,
                networkId: chainId,
            });
        });
    }


    const authenticate = async () => {
        await metamaskAuth({
            setAccounts: props.setAccounts,
            setNetwork: props.setNetwork,
            setRefresher: props.setRefresher,
            setSigner: props.setSigner
        });
    }


    const [subRoutesAnchorEl, subRoutesSetAnchorEl] = React.useState<null | HTMLElement>(null);
    const subRoutesOpen = Boolean(subRoutesAnchorEl);
    const handleRouteClick = (event: React.MouseEvent<HTMLElement>, subRoutes: Array<ISubRoute>) => {
        subRoutesSetAnchorEl(event.currentTarget);
        setsubRoutes(subRoutes);
    };
    const handleSubRoutesClose = () => {
        subRoutesSetAnchorEl(null);
    };

    const handleSubRouteClick = (subRoute: ISubRoute) => {
        navigate(subRoute.PATH + (subRoute.personal ? "/personal/" + props.accounts[0] : ""),{ replace: true });
        subRoutesSetAnchorEl(null);
    }



    return (
        <>
            <AppBar 
                position="fixed" 
                style={{
                    ...props.theme as any,
                    backgroundColor: props.theme.palette.mode === 'dark' ? props.theme.palette.common.black : props.theme.palette.common.white,
                    color: '#fff',
                    borderEndEndRadius: '30px',
                    borderEndStartRadius: '30px',
                    padding: '0.7rem'
                }}
            >
                <Container>
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                            mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 400,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                            className='!cursor-pointer'
                        >
                            <Link to="/">
                                <img
                                    src="logo/index.png"
                                    style={{ 
                                        height: 90, 
                                        width: 90, 
                                        color: props.theme.palette.mode === 'dark' ? props.theme.palette.common.white : props.theme.palette.common.black
                                    }}
                                    alt="Tradyr"
                                />
                            </Link>
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } , justifyContent: 'center' }}>
                            {
                                props.network && 
                                <>
                                    {
                                        props.network.ROUTES.map((route: IRoute, id: any) => 
                                        <div key={id}>
                                            {
                                                route.navbarOption && 
                                                <Button
                                                    onClick={(e: React.MouseEvent<HTMLElement>) => handleRouteClick(e, route.subroutes)}
                                                    sx={{ 
                                                        my: 2, 
                                                        display: 'block', 
                                                        paddingRight: '3rem', 
                                                        paddingLeft: '3rem', 
                                                        color: props.theme.palette.mode === 'dark' ? props.theme.palette.common.white : props.theme.palette.common.black
                                                    }}
                                                >
                                                    {route.NAME}
                                                    {
                                                        route.subroutes.length > 0 && 
                                                        <ArrowDropDownIcon/>
                                                    }
                                                </Button>
                                            }
                                        </div>
                                        )
                                    }
                                </>
                            }
                        </Box>

                        <Typography
                            variant="h5"
                            noWrap
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                            className='!cursor-pointer'
                        >
                            <Link to="/">
                                <img
                                    src="logo/index.png"
                                    style={{ 
                                        height: 90, 
                                        width: 90, 
                                        color: props.theme.palette.mode === 'dark' ? props.theme.palette.common.white : props.theme.palette.common.black
                                    }}
                                    alt="Tradyr"
                                />
                            </Link>
                        </Typography>

                        <Box sx={{ flexGrow: 0 }}>

                            <Tooltip title="Account menu">
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    {
                                        props.accounts && props.accounts.length > 0 ? (
                                            <Avatar alt="Account menu" style={{ border: 0 }}>
                                                <Blockies
                                                    seed={props.accounts[0]}
                                                    size={14}
                                                    scale={3}
                                                    color='#dfe'
                                                    spotColor='#abc'
                                                    className='identicon rounded-full'
                                                />
                                            </Avatar>
                                        ) : (
                                            <AccountBalanceWalletIcon 
                                                sx={{ 
                                                    width: 40, 
                                                    height: 40, 
                                                    color: props.theme.palette.mode === 'dark' ? props.theme.palette.common.white : props.theme.palette.common.black
                                                }}
                                            />
                                        )
                                    }
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Toolbar>
                </Container>


                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        borderEndEndRadius: '30px',
                        borderEndStartRadius: '30px',
                        borderTopLeftRadius: '30px',
                        padding: '1.5rem',
                        paddingTop: '0.2rem',
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        },
                    },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >

                    <div className="relative flex py-3 items-center" style={{ paddingTop: '0' }}>
                        <div className="flex-grow border-t border-gray-500"></div>
                        <span className="flex-shrink mx-4 text-gray-500 text-sm">Connection</span>
                        <div className="flex-grow border-t border-gray-500"></div>
                    </div>

                    <div style={{ padding: '1rem', paddingTop: '0', textAlign: 'center', justifyContent: 'center' }}>
                        {
                            props.accounts && props.accounts.length > 0 ? (
                                <>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        justifyContent: 'center',
                                        textAlign: 'center'
                                    }}>
                                        <Avatar alt={props.accounts[0]} style={{ border: 0 }}>
                                            <Blockies
                                                seed={props.accounts[0]}
                                                size={14}
                                                scale={3}
                                                color='#dfe'
                                                spotColor='#abc'
                                                className='identicon rounded-full'
                                            />
                                        </Avatar>
                                        <Typography variant="overline" display="contents" gutterBottom sx={{ fontWeight: 900, fontSize: 18, textAlign: 'center', justifyContent: 'center' }}>
                                            {props.accounts[0].substring(0,5) + ".." + props.accounts[0].substring(props.accounts[0].length-3,props.accounts[0].length)}
                                        </Typography>
                                    </div>
                                    {
                                        props.network && 
                                        <>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                                justifyContent: 'center',
                                                textAlign: 'center'
                                            }}>
                                                <Avatar alt={props.network.NAME} src={props.network.ICON} style={{ border: 0 }}/>
                                                <Typography variant="overline" display="contents" gutterBottom sx={{ fontWeight: 200, fontSize: 12, textAlign: 'center', justifyContent: 'center' }}>
                                                    {props.network.NAME}
                                                </Typography>
                                            </div>
                                        </>
                                    }
                                </>
                            ) : (
                                <IconButton onClick={authenticate}>
                                    <LoginIcon 
                                        sx={{ 
                                            width: 40, 
                                            height: 40, 
                                            color: props.theme.palette.common.black
                                        }}
                                    />
                                </IconButton>
                            )
                        }
                        <Button onClick={() => { setNetworkSelectModal(!networkSelectModal); handleClose(); }}>Change network</Button>
                    </div>

                    <div className="relative flex py-3 items-center">
                        <div className="flex-grow border-t border-gray-500"></div>
                        <span className="flex-shrink mx-4 text-gray-500 text-sm">Preferences</span>
                        <div className="flex-grow border-t border-gray-500"></div>
                    </div>

                    <div style={{ padding: '1rem', paddingTop: '0', textAlign: 'center', justifyContent: 'center' }}>
                        <div>
                            <span style={{ fontWeight: 600 }}>Theme: {theme.palette.mode === 'dark' ? "Dark" : "Light"}</span>
                            <IconButton onClick={() => props.setTheme()} color="inherit">
                                {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                            </IconButton>
                        </div>

                        <div>
                            <Button
                                id="demo-customized-button"
                                aria-controls={homepageListOpen ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={homepageListOpen ? 'true' : undefined}
                                disableElevation
                                onClick={handleHomepageClick}
                                endIcon={<ArrowDropDownIcon />}
                            >
                                <>
                                    Homepage list: <br/>
                                    {props.homepageList}
                                </>
                            </Button>
                            <StyledMenu
                                id="demo-customized-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'demo-customized-button',
                                }}
                                style={{
                                    justifyContent: 'center',
                                    textAlign: 'center'
                                }}
                                anchorEl={homepageListanchorEl}
                                open={homepageListOpen}
                                onClose={handleHomepageClose}
                            >
                                {
                                    props.network && 
                                    <div>
                                        {
                                            props.network.ROUTES.map((route: IRoute, id: any) => 
                                                <MenuItem key={id} onClick={() => handleHomepageList(route.CATEGORY)}>
                                                    {route.NAME}
                                                </MenuItem>
                                            )
                                        }
                                    </div>
                                }
                            </StyledMenu>
                        </div>
                    </div>

                    <div className="relative flex py-3 items-center">
                        <div className="flex-grow border-t border-gray-500"></div>
                        <span className="flex-shrink mx-4 text-gray-500 text-sm">Routes</span>
                        <div className="flex-grow border-t border-gray-500"></div>
                    </div>

                    {
                        props.network && 
                        <div>
                            {
                                props.network.ROUTES.map((route: IRoute, id: number) => 
                                <MenuItem 
                                    key={id} 
                                    id="subRoutes-menu-button"
                                    onClick={(e: React.MouseEvent<HTMLElement>) => handleRouteClick(e, route.subroutes)}
                                    sx={{ 
                                        padding: '1rem',
                                        textAlign: 'center',
                                        justifyContent: 'center'
                                    }}
                                    aria-controls={open ? 'subRoutes-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <Typography textAlign="center">{route.NAME}</Typography>
                                    {
                                        route.subroutes.length > 0 && 
                                        <ArrowDropDownIcon/>
                                    }
                                </MenuItem>
                                )
                            }
                        </div>
                    }

                </Menu>

            </AppBar>

            <Dialog
                open={networkSelectModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setNetworkSelectModal(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                {
                    NETWORKS && 
                    <div style={{ padding: '2rem' }}>
                        {
                            NETWORKS.map((NETWORK: IPublicNetwork | IPrivateNetwork, id: any) => 
                            <div key={id}>
                                {
                                    NETWORK.SALES && 
                                    <Tooltip placement="right" title={NETWORK.PUBLIC ? 'This is a public network. Public networks are used to trade NFTs' : 'This is a private network. Private networks are used to trade all sort of items on non-blockchain related games.'}>
                                        <MenuItem onClick={() => select(NETWORK)} sx={{ padding: '1rem' }}>
                                            <Box sx={{ 
                                                display: 'contents', 
                                                alignItems: 'center',
                                                flexWrap: 'wrap', 
                                                color: 'white'
                                            }}>
                                                <Avatar src={NETWORK.ICON} />
                                                <span 
                                                    className="ml-3"
                                                    style={{
                                                        color: props.theme.palette.mode === 'dark' ? props.theme.palette.common.white : props.theme.palette.common.black 
                                                    }}
                                                >
                                                    {NETWORK.NAME}
                                                </span>        
                                                <div style={{ paddingLeft: '1.5rem' }}>
                                                    {
                                                        NETWORK.PUBLIC ? (
                                                            <Chip label="Public" variant="outlined" color="primary" />
                                                        ) : (
                                                            <Chip label="Private" variant="outlined" color="primary" />
                                                        )
                                                    }
                                                </div>
                                            </Box>
                                        </MenuItem>
                                    </Tooltip>
                                }
                            </div>
                            )
                        }
                    </div>
                }
            </Dialog>

            <StyledsubRoutesMenu
                id="subRoutes-menu"
                MenuListProps={{
                    'aria-labelledby': 'subRoutes-menu-button',
                }}
                anchorEl={subRoutesAnchorEl}
                open={subRoutesOpen}
                onClose={handleSubRoutesClose}
            >
                {
                    subRoutes.map((subRoute: ISubRoute, id: any) => 
                        <MenuItem style={{ 
                            padding: '1.6rem', 
                            justifyContent: 'center',
                            textAlign: 'center'
                        }} disableRipple key={id} onClick={() => handleSubRouteClick(subRoute)}>
                            {subRoute.NAME}
                        </MenuItem>
                    )
                }
            </StyledsubRoutesMenu>
        </>
    );
}
