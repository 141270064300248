import { ethers } from 'ethers';
import { toHex } from 'web3-utils';
import { ERC721__factory, IERC1155__factory } from '../../../contracts/public/typechain-types';
import { RequiredApproval } from '../create/public/requiredApproval';


export interface HandlePublicCreationApprovementsParams {
    assets: Array<any>,
    signer: ethers.providers.JsonRpcSigner,
    contractAddress: string
}

export interface GenerateRequiredApprovementsOutput {
    requiredApprovals: Array<RequiredApproval>,
    contractAddresses: Array<string>,
    assetIds: Array<string>,
    assetTypes: Array<number>,
    assetAmounts: Array<number>
}

export const generateRequiredApprovements = async (input: HandlePublicCreationApprovementsParams): Promise<GenerateRequiredApprovementsOutput> => {
    let filteredAssets = [];
    
    // Filtering all the assets
    for ( let i = 0 , l = input.assets.length ; i < l ; ++i ) {
      if ( input.assets[i].quantity >= 1 ) {
        filteredAssets.push(input.assets[i]);
      }
    }

    // Formating params
    const contractAddresses: Array<string> = filteredAssets.map((asset: any) => { return String(asset.tokenAddress); });
    const assetIds: Array<string> = filteredAssets.map((asset: any) => { return toHex(asset.tokenId); });
    const assetAmounts: Array<number> = filteredAssets.map((asset: any) => { return Number(asset.quantity); });
    const assetTypes: Array<number> = filteredAssets.map((asset: any) => { 
        if ( asset.contractType.indexOf("ERC1155") !== - 1 )
            return 1;
        if ( asset.contractType.indexOf("ERC721") !== - 1 )
            return 0;
        return -1;
    });
    
    let output: Array<RequiredApproval> = [];
    for ( let i = 0 , l = contractAddresses.length ; i < l && l === assetIds.length && l === assetTypes.length ; ++i ) {
        output.push({
            contractAddress: contractAddresses[i],
            id: assetIds[i],
            type: assetTypes[i],
            validated: false
        });
    }

    return {
        assetIds: assetIds,
        assetTypes: assetTypes,
        contractAddresses: contractAddresses,
        requiredApprovals: output,
        assetAmounts: assetAmounts
    };

}



export interface filterRequiredApprovalsParams {
    requiredApprovals: Array<RequiredApproval>;
    signer: ethers.providers.JsonRpcSigner;
    contractAddressToApprove: string;
}

export interface solveFilteredRequiredApprovalsParams extends filterRequiredApprovalsParams {
    setSolvedApprovals: Function;
}

export const solveFilteredRequiredApprovals = async (input: solveFilteredRequiredApprovalsParams): Promise<Array<RequiredApproval>> => {

    let tx;

    for ( let i = 0 , l = input.requiredApprovals.length ; i < l ; ++i ) {

        switch ( input.requiredApprovals[i].type ) {
            case 1:

                try {
                    const erc1155Contract = IERC1155__factory.connect(input.requiredApprovals[i].contractAddress, input.signer);
                    tx = await erc1155Contract.setApprovalForAll(input.contractAddressToApprove, true);
                    await tx.wait();
                    input.requiredApprovals[i].validated = true;
                    input.setSolvedApprovals(input.requiredApprovals.slice(0,i+1));
                } catch (err: any) {
                    console.error(Error(err).message);
                    input.requiredApprovals[i].validated = false;
                    input.setSolvedApprovals(input.requiredApprovals.slice(0,i+1));
                }

            break;

            case 0:

                try {
                    const erc721Contract = ERC721__factory.connect(input.requiredApprovals[i].contractAddress, input.signer);
                    tx = await erc721Contract.approve(input.contractAddressToApprove, input.requiredApprovals[i].id);
                    await tx.wait();
                    input.requiredApprovals[i].validated = true;
                    input.setSolvedApprovals(input.requiredApprovals.slice(0,i+1));
                } catch (err: any) {
                    console.error(Error(err).message);
                    input.requiredApprovals[i].validated = false;
                    input.setSolvedApprovals(input.requiredApprovals.slice(0,i+1));
                }

            break;
        } 

    }

    return input.requiredApprovals;
    
}



export const filterRequiredApprovals = async (input: filterRequiredApprovalsParams): Promise<Array<RequiredApproval>> => {
    
    let filteredRequiredApprovals: Array<RequiredApproval> = [];

    let approvedErc1155Contracts = [], isApproved;
    const signerAddress = await input.signer.getAddress();

    for ( let i = 0 , l = input.requiredApprovals.length ; i < l ; ++i ) {

        switch ( input.requiredApprovals[i].type ) {

            case 1:

                if ( approvedErc1155Contracts.indexOf(input.requiredApprovals[i].contractAddress) === -1 ) {

                    approvedErc1155Contracts.push(input.requiredApprovals[i].contractAddress);

                    const erc1155Contract = IERC1155__factory.connect(input.requiredApprovals[i].contractAddress, input.signer);

                    isApproved = await erc1155Contract.isApprovedForAll(signerAddress,input.contractAddressToApprove);
                    
                    if ( !isApproved ) {
                        filteredRequiredApprovals.push({
                            contractAddress: input.requiredApprovals[i].contractAddress,
                            id: input.requiredApprovals[i].id,
                            type: input.requiredApprovals[i].type,
                            validated: false
                        });
                    }

                }

            break;

            case 0:

                const erc721Contract = ERC721__factory.connect(input.requiredApprovals[i].contractAddress, input.signer);

                isApproved = await erc721Contract.getApproved(input.requiredApprovals[i].id);

                if ( isApproved !== input.contractAddressToApprove ) {
                    filteredRequiredApprovals.push({
                        contractAddress: input.requiredApprovals[i].contractAddress,
                        id: input.requiredApprovals[i].id,
                        type: input.requiredApprovals[i].type,
                        validated: false
                    });
                }

            break;

        } 
        
    }

    return filteredRequiredApprovals;
    
}