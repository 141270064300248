import { Theme } from '@mui/material/styles';

export interface StatStyleParams {
    child: any;
    theme: Theme;
}

export const StatStyle = (params: StatStyleParams) => {
    return (
        <>
            {
                params.theme && 
                <div style={{
                    padding: '1.5rem',
                    borderRadius: '18px'
                }}>
                    <div style={{
                        alignItems: 'center',
                        flexWrap: 'wrap', 
                        color: params.theme.palette.mode === 'dark' ? params.theme.palette.common.white : params.theme.palette.grey[900]
                    }}>
                        {params.child}
                    </div>
                </div>   
            }
        </>
    );
}