import { useState, useEffect, createContext, useMemo } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { NavigationBar } from '../common/navigation/index';
import { List } from '../common/lists/index';

import AuctionCreate from '../auctions/create/index';
import AuctionView from '../auctions/view/index';

import RentsCreate from '../rents/create/index';
import RentsView from '../rents/view/index';

import SalesCreate from '../sales/create/index';
import SalesView from '../sales/view/index';

import Login from "../auth/Login.jsx";
import Signup from "../auth/Signup.jsx";

import { NETWORKS, getNetwork, IPublicNetwork, IPrivateNetwork } from './utility';
import Snackbar from '../common/snackbar/index';
import { ThemeProvider, createTheme, Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { ethers } from 'ethers';
import { metamaskAuth } from '../common/methods/metamaskAuth';


const ColorModeContext = createContext({ toggleColorMode: () => {} });

export interface IDefaultRouteProps {
  accounts: Array<string>,
  setError: Function, 
  setSuccess: Function,
  network: IPublicNetwork | IPrivateNetwork,
  theme: Theme,
  key: number
  signer: ethers.providers.JsonRpcSigner
};

export default function App() {

  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [network, setNetwork] = useState<IPublicNetwork | IPrivateNetwork>(getNetwork());
  const [signer, setSigner] = useState<ethers.providers.JsonRpcSigner>(new ethers.providers.Web3Provider(window.ethereum, "any").getSigner());
  const [accounts, setAccounts] = useState<Array<string>>([]);
  const [refresher, setRefresher] = useState<number>(0);
  const [mode, setMode] = useState<'light' | 'dark'>(localStorage.getItem('theme') === 'light' ? 'light' : 'dark');
  const [homepageList, setHomepageList] = useState<string>(localStorage.getItem("homepageList") || "sales");
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          let updatedTheme: 'light' | 'dark' = prevMode === 'light' ? 'dark' : 'light';
          localStorage.setItem('theme', updatedTheme);
          return updatedTheme;
        }
        );
      },
    }),
    [],
  );
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode],
  );

  const connect = async () => {
    await metamaskAuth({
      setAccounts: setAccounts,
      setNetwork: setNetwork,
      setRefresher: setRefresher,
      setSigner: setSigner
    });
  }

  useEffect(() => {
    connect();
  }, []);

  const snackbarProps = {
    autoHideDuration: 6000
  }

  const defaultRouteProps: IDefaultRouteProps = {
    accounts: accounts,
    setError: setError, 
    setSuccess: setSuccess,
    network: network,
    theme: theme,
    key: refresher,
    signer: signer
  };

  return (
    <>
      <BrowserRouter>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>

            <Box sx={{
              width: '100%',
              minHeight: '100vh',
              height: '100%',
              display: 'inline-block',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'background.default',
              color: 'text.primary'
            }}>

              <NavigationBar 
                {...defaultRouteProps}
                NETWORKS={NETWORKS}
                setNetwork={setNetwork}
                setRefresher={setRefresher}
                setAccounts={setAccounts}
                setSigner={setSigner}
                homepageList={homepageList} 
                setHomepageList={setHomepageList}
                category={""}
                network={network}
                canCreate={false}
                setTheme={colorMode.toggleColorMode}
              />

              <Routes>

                <Route path="/login" element={<Login/>} />

                <Route path="/signup" element={<Signup/>} />

                <Route path='/' element={<List 
                      category={homepageList}
                      {...defaultRouteProps} 
                      canCreate={true} 
                    />
                  } 
                />

                <Route path='/auctions/create' element={<AuctionCreate 
                      category="auctions" 
                      canCreate={true}
                      {...defaultRouteProps} 
                    />
                  }
                />

                <Route path='/:category/list/:relationship' element={<List 
                      {...defaultRouteProps} 
                      canCreate={true}
                    />
                  } 
                />

                <Route path='/:category/list/:relationship/personal/:account' element={<List 
                      {...defaultRouteProps} 
                      canCreate={false}
                    />
                  } 
                />

                <Route path='/auctions/view/:id' element={<AuctionView 
                      category="auctions" 
                      {...defaultRouteProps} 
                      canCreate={false}
                    />
                  } 
                />

                <Route path='/sales/create' element={<SalesCreate 
                      category="sales" 
                      {...defaultRouteProps} 
                      canCreate={true}
                    />
                  }
                />

                <Route path='/sales/view/:id' element={<SalesView 
                      category="sales" 
                      {...defaultRouteProps} 
                      canCreate={false}
                    />
                  } 
                />

                <Route path='/rents/create' element={<RentsCreate 
                      category="rents" 
                      {...defaultRouteProps} 
                      canCreate={true}
                    />
                  }
                />

                <Route path='/rents/view/:id' element={<RentsView 
                      category="rents" 
                      {...defaultRouteProps} 
                      canCreate={false}
                    />
                  } 
                />

              </Routes>

              <Snackbar 
                open={error} 
                onClose={() => setError(null)} 
                message={error} 
                {...snackbarProps}
                severity={4}
              />

              <Snackbar 
                open={success} 
                onClose={() => setSuccess(null)} 
                message={success} 
                {...snackbarProps} 
                severity={2}
              />

            </Box>

          </ThemeProvider>
        </ColorModeContext.Provider>
      </BrowserRouter>
    </>
  );
}