import { httpSimpleGetRequest } from '../../app/utility';

export const list = (
    apiUrl: string, 
    category: string,
    chainId: string | number,
    selection: string,
    setError: Function,
    setterHook: Function
) => {
    httpSimpleGetRequest(apiUrl + '/' + chainId + '/' + category + '/list/' + selection)
    .then((response: any | undefined) => {
        if ( response ) { 
            if ( response.errorCode !== undefined && response.errorMessage !== undefined ) {
                setError(response.errorMessage);
                if ( response.errorCode === null && response.errorMessage === null ) {
                    setterHook(response.entities);
                }
            }
        }
    })
    .catch((err: any) => {
        if ( err ) {
            console.error(Error(err).message);
        }
    });
}