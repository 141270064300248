import { Index as PublicList } from "./public";
import { Index as PrivateList } from "./private";
import { IListRouteProps } from '../dto/routeProps';
import { AddGameCard } from "../cards/private/addGameCard";


export const List = (props: IListRouteProps) => {
    return (
        <div style={{ marginTop: '180px' }}>
            {
                props.network ? (
                    <>
                        {
                            props.network.PUBLIC ? (
                                <PublicList {...props} />
                            ) : (
                                <PrivateList {...props} />
                            )
                        }
                    </>
                ) : (
                    <>
                        <AddGameCard theme={props.theme} />
                    </>
                )
            }
        </div>
    );
}