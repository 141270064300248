import { Theme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';


export interface ViewButtonParams {
    onClick: Function,
    theme: Theme,
    label: string
}

export const ViewButton = (props: ViewButtonParams) => {
    return (
        <Button 
            variant='outlined' 
            size='large' 
            endIcon={<DoneIcon />} 
            onClick={() => props.onClick()}
            style={{
                color: props.theme.palette.mode === 'dark' ? props.theme.palette.common.white : props.theme.palette.grey[900],
                border: 0,
                fontWeight: 900,
                paddingRight: '0.2rem',
                paddingLeft: '0.2rem'
            }}
        >
            <span style={{ verticalAlign: 'text-bottom' }}>
                {props.label}
            </span>
        </Button>
    );
}