import { useState, useEffect } from 'react';
import { toHex, toWei } from 'web3-utils';
import { address0x0, messages } from '../../app/utility';
import { ContractTransaction } from 'ethers';
import { PublicAssetsHandler } from '../../common/create/public/publicAssetsHandler';
import { triggerCreationPublicAssets, TriggerCreationPublicAssetsOutput } from '../../common/methods/triggerCreationPublicAssets';
import { handlePublicCreateTransactionReceipt } from '../../common/create/public/handlePublicTransactionReceipt';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { getCreationPublicAssets } from '../../common/methods/getCreationPublicAssets';
import { RequiredApproval } from '../../common/create/public/requiredApproval';
import { PublicRoute } from '../../common/dto/routeProps';
import { TextInput } from '../../common/inputs/textInput';
import { IPublicAuctionAsset } from '../../common/dto/assets/publicAuctionAsset';
import { handlePriceInput } from '../../common/methods/handlePriceInput';


export const Index = (props: PublicRoute) => {
  const navigate: NavigateFunction = useNavigate();
  const [assets, setAssets] = useState<Array<IPublicAuctionAsset>>([]);
  const [receivedAssetsResponse, setReceivedAssetsResponse] = useState<boolean>(false);
  const [price, setPrice] = useState<string>('');
  const [requiredApprovals, setRequiredApprovals] = useState<Array<RequiredApproval>>([]);
  const [solvedRequiredApprovals, setSolvedRequiredApprovals] = useState<Array<RequiredApproval>>([]);
  const [tax, setTax] = useState<string>('');


  useEffect(() => {

    getCreationPublicAssets({
      accounts: props.accounts,
      network: props.network,
      setAssets: setAssets,
      setError: props.setError,
      setRequestFinished: setReceivedAssetsResponse
    });

  }, []);


  const create = async () => {
    try {
      if ( Number(price) <= 0 ) {
        props.setError("Invalid price, must be greater than 0");
        setPrice('');
        return;
      }

      const formattedAndVerifiedAssets: TriggerCreationPublicAssetsOutput = await triggerCreationPublicAssets({
        assets: assets,
        network: props.network,
        setApprovals: setRequiredApprovals,
        setError: props.setError,
        setSolvedApprovals: setSolvedRequiredApprovals,
        signer: props.signer,
        contractAddressToApprove: props.network.AUCTIONS.address
      });

      const tx: ContractTransaction = await props.network.AUCTIONS
      .connect(props.signer)
      .create(
        formattedAndVerifiedAssets.contractAddresses, // nft addresses
        formattedAndVerifiedAssets.assetIds, // nft ids
        formattedAndVerifiedAssets.assetAmounts, // nft amounts
        formattedAndVerifiedAssets.assetTypes, // nft types
          toHex(toWei(String(price))),
          toHex(toWei(String(tax))),
          address0x0,
          {
            gasLimit: props.network.GAS_LIMIT[12]
          }
      );

      await handlePublicCreateTransactionReceipt({
        navigate: navigate,
        eventName: "NewAuction",
        setError: props.setError,
        tx: tx
      });
    } catch (err: any) {
      console.error(Error(err).message);
      props.setError(messages.METAMASK_ERROR);
    }

  }

  return (
    <PublicAssetsHandler 
      {...props} 
      assets={assets} setAssets={setAssets} 
      receivedAssetsResponse={receivedAssetsResponse} setReceivedAssetsResponse={setReceivedAssetsResponse} 
      requiredApprovals={requiredApprovals} setRequiredApprovals={setRequiredApprovals} 
      solvedRequiredApprovals={solvedRequiredApprovals} setSolvedRequiredApprovals={setSolvedRequiredApprovals}
      child={
        <>
          <TextInput 
            setValue={(e: string) => handlePriceInput(e, setPrice)} 
            theme={props.theme} 
            label={"Price (in " + props.network.NATIVE_CURRENCY.name + ")"} 
            value={price}
          />
          <TextInput 
            onClick={create}
            setValue={(e: string) => handlePriceInput(e, setTax)} 
            theme={props.theme} 
            label={"Tax (in " + props.network.NATIVE_CURRENCY.name + ")"} 
            value={tax}
          />
        </>
      }
    />
  );
}
