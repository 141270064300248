import { useNavigate } from 'react-router-dom';

export interface PublicListCardParams {
  item: any,
  image: string,
  category: string
}

export const ListCard = (params: PublicListCardParams) => {
    const navigate = useNavigate();
    return (
      <div 
        className='!inline-grid !ml-4 !mr-4 !mb-4 !cursor-pointer shadow-xl !rounded-2xl' 
        style={{
          width: '18rem',
          height: '18rem'
        }}
        onClick={() => navigate('/' + params.category + '/view/' + params.item.tokenId)}
      >
        <img 
          className='!h-72 !w-72 !rounded-2xl'
          src={params.image} 
          alt={params.item.name}
        />
      </div>
    )
}