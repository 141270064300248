import { ContractReceipt, ContractTransaction } from "ethers";
import { messages } from "../../../app/utility";
import { IHandlePublicTransactionReceiptParams } from "./handlePublicTransactionReceiptParams";

export const handlePublicCreateTransactionReceipt = async (
    props: IHandlePublicTransactionReceiptParams
) => {
    props.tx.wait()
    .then((receipt: ContractReceipt) => {
        if ( receipt.events ) {
            for ( let i = 0 , l = receipt.events.length ; i < l ; ++i ) {
            if ( receipt.events[i].event === props.eventName ) {
                if ( receipt.events[i].args ) {
                    props.navigate(window.location.pathname.replace("create","") + "view/" + Number((receipt.events[i].args as any).id));
                } else {
                props.setError("Something went wrong with your transaction");
                }
            }
            }
        } else {
            props.setError("Something went wrong with your transaction");
        }
    }).catch((err: any) => {
        console.error(Error(err).message);
        props.setError(messages.METAMASK_ERROR);
    });
}