import { Index as PublicAuctionsView } from "./public";
import { Index as PrivateAuctionsView } from "./private";
import { IRouteProps } from "../../common/dto/routeProps";

export default function Index(props: IRouteProps) {

    return (
        <>
            {
                props.network && 
                <>
                    {
                        props.network.PUBLIC ? (
                            <PublicAuctionsView {...props} />
                        ) : (
                            <PrivateAuctionsView {...props} />
                        )
                    }
                </>
            }
        </>
    );
}