import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { listOne } from '../../common/methods/listOne';
import { BigNumber, ContractReceipt, ContractTransaction } from 'ethers';
import { PublicRoute } from '../../common/dto/routeProps';
import { PublicViewImage } from '../../common/cards/public/viewImage';
import { PublicViewStats } from '../../common/cards/public/viewStats';
import { IPublicSaleAsset } from '../../common/dto/assets/publicSaleAsset';
import { generator } from '../../common/skeletons/generator';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid, IconButton } from '@mui/material';
import { Container } from '@mui/system';


export const Index = (props: PublicRoute) => {
    const [item, setItem] = useState<IPublicSaleAsset | null>(null);
    const [currentAssetIndex, setCurrenAssetIndex] = useState(0);
    const navigate = useNavigate();
    const { id } = useParams();


    useEffect(() => {
        try {
            const fetch = async () => {
                if ( id && props.network ) {
                    await listOne(
                        props.network.API_URL,
                        props.category,
                        props.network.CHAIN_ID,
                        id,
                        props.setError,
                        setItem
                    );
                }
            }
            fetch();
        } catch (err) {
            props.setError('Internal error, try again later!');
        }
    }, [id, props.network]);


    const cancel = async () => {
        try {
            if ( item && props.network ) {
                const tx: ContractTransaction = await props.network.SALES
                .connect(props.signer)
                .cancel(BigNumber.from(item.tokenId),{
                    from: props.accounts[0]
                });
                tx.wait().then((receipt: ContractReceipt) => {
                    setItem({
                        ...item,
                        status: 1
                    });
                    props.setSuccess('Sale has been successfully cancelled!');
                });
            }
        } catch (err) {
            console.error(err);
            props.setError('Failed to cancel purchase');
        }
    }

    const buy = async () => {
        try {
            if ( item && props.network && props.signer ) {
                const tx: ContractTransaction = await props.network.SALES
                .connect(props.signer)
                .buy(BigNumber.from(item.tokenId),{
                    value: item.price,
                    gasLimit: props.network.GAS_LIMIT[12]
                });
                tx.wait().then((receipt: ContractReceipt) => {
                    setItem({
                        ...item,
                        status: 1
                    });
                    props.setSuccess('Assets have been successfully purchased!');
                });
            }
        } catch (err) {
            console.error(err);
            props.setError('Failed to purchase asset');
        }
    }

    const buttons = (item: any) => {
        return <div className='grid grid-cols-1 divide-y'>
            <div className='px-6 py-4'>
                <div className='grid grid-cols-2 justify-content-center text-center'>
                    <div>
                        {
                            item.owner && props.accounts[0] && 
                            <>
                                {
                                    item.owner.toLowerCase() === props.accounts[0].toLowerCase() && item.status === 0 && 
                                    <Button 
                                        variant='outlined' 
                                        size='large' 
                                        style={{
                                            color: props.theme.palette.mode === 'dark' ? props.theme.palette.common.white : props.theme.palette.grey[900],
                                            backgroundColor: props.theme.palette.mode === 'light' ? props.theme.palette.common.white : 'inherit',
                                            borderColor: props.theme.palette.mode === 'dark' ? props.theme.palette.grey[800] : props.theme.palette.grey[900],
                                            borderRadius: '10px',
                                            padding: '1.2rem'
                                        }}
                                        onClick={cancel}
                                    >
                                        <span>
                                            Cancel
                                        </span>
                                    </Button>
                                }
                            </>
                        }
                    </div>
                    <div>
                        {
                            item.owner && props.accounts[0] && 
                            <>
                                {
                                    item.owner.toLowerCase() !== props.accounts[0].toLowerCase() && item.status === 0 && 
                                    <Button 
                                        variant='outlined' 
                                        size='large' 
                                        style={{
                                            color: props.theme.palette.mode === 'dark' ? props.theme.palette.common.white : props.theme.palette.grey[900],
                                            borderRadius: '10px',
                                            padding: '1.2rem'
                                        }}
                                        onClick={buy}
                                    >
                                        Purchase
                                    </Button>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>;
    }

    return (
        <>
            <div className='p-10 flex justify-center items-center' style={{ marginTop: '180px' }}>
                {
                    item ? (
                        <>
                            <div className='max-w-2xl rounded-3xl overflow-hidden !shadow-lg'>
                                <PublicViewImage 
                                    url={item.items[currentAssetIndex].metadata.metadata.image} 
                                    explorer={props.network.EXPLORER}
                                    description={item.items[currentAssetIndex].metadata.metadata.description}
                                    name={item.items[currentAssetIndex].metadata.name}
                                    owner={item.items[currentAssetIndex].metadata.ownerOf} 
                                    setCurrenAssetIndex={setCurrenAssetIndex}
                                    totalItems={item.items.length}
                                    currentAssetIndex={currentAssetIndex}
                                />
                                <PublicViewStats 
                                    theme={props.theme}
                                    price={item.price} 
                                    currency={item.currency} 
                                    nativeCurrency={props.network.NATIVE_CURRENCY}
                                />
                                <Divider/>
                                {buttons(item)}
                            </div>
                        </>
                    ) : (
                        <div className='max-w-2xl rounded-3xl overflow-hidden !shadow-lg w-full w-100 h-100'>
                            {
                                generator(
                                    1, 
                                    props.theme, 
                                    {
                                        'minWidth': '100%',
                                        'minHeight': '42rem'
                                    }
                                )
                            }
                            {
                                generator(
                                    1, 
                                    props.theme, 
                                    {
                                        'minWidth': '100%',
                                        'minHeight': '4rem'
                                    }
                                )
                            }
                        </div>
                    )
                }
            </div>
        </>
    );
}