import { useState, useEffect } from 'react';
import Card from '../cards/private/default';
import { CreatorCard } from '../cards/private/creator';
import { list as listOwned } from '../methods/list';
import { PrivateListRoute } from '../dto/routeProps';
import { generator } from '../skeletons/generator';
import { useParams } from 'react-router-dom';
import { AddGameCard } from '../cards/private/addGameCard';


export const Index = (props: PrivateListRoute) => {
    const [receivedAssetsResponse, setReceivedAssetsResponse] = useState<boolean>(false);
    const [list, setList] = useState<Array<any>>([]);
    const { category, relationship } = useParams();

    const getCategory = (): string => {
        if ( props.category )
            return props.category;
        if ( category )
            return category;
        if ( localStorage.getItem("homepageList") ) {
            return String(localStorage.getItem("homepageList"));
        }
        return "sales";
    }

    const getSubRoute = (): string => {
        if ( props.subroute )
            return props.subroute;
        if ( relationship )
            return relationship;
        if ( localStorage.getItem("homepageSubRoute") ) {
            return String(localStorage.getItem("homepageSubRoute"));
        }
        return "marketplace";
    }

    useEffect(() => {
        try {
            const fetch = async () => {
                if ( props.network ) {
                    setList([]);
                    setReceivedAssetsResponse(false);
                    await listOwned(
                        props.network.API_URL,
                        getCategory(),
                        props.network.CHAIN_ID,
                        getSubRoute(),
                        props.setError,
                        setList
                    );
                    setReceivedAssetsResponse(true);
                }
            }
            fetch();
        } catch (err) {
            props.setError("Internal error, try again later!");
        }
    }, [props.category]);



    return (
        <div className='row mt-5 mb-5 ml-5 mr-5 justify-content-center text-center'>
            <AddGameCard theme={props.theme} />
            {
                props.canCreate && 
                <CreatorCard category={getCategory()} theme={props.theme} />
            }
            {
                receivedAssetsResponse ? (
                    <>
                        {
                            list.map((item,id) => 
                                <Card key={id} item={item} category={getCategory()} />
                            )
                        }
                    </>
                ) : (
                    <>
                        {generator(9, props.theme)}
                    </>
                )
            }
        </div>
    );
}