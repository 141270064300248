import { Index as PublicAuctionCreate } from './public';
import { Index as PrivateAuctionCreate } from './private';
import { IRouteProps } from '../../common/dto/routeProps';


export default function Index(props: IRouteProps) {

  return (
    <>
    {
        props.network && 
        <>
            {
                props.network.PUBLIC ? (
                    <PublicAuctionCreate {...props} />
                ) : (
                    <PrivateAuctionCreate {...props} />
                )
            }
        </>
    }
</>
  );
}
