import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import SendIcon from '@mui/icons-material/Send';
import FormControl from '@mui/material/FormControl';
import { Theme } from '@mui/material';


export interface ITextInput {
    theme: Theme,
    value: any,
    setValue: Function,
    onClick?: Function,
    label: string
}

export const TextInput = (props: ITextInput) => {
  return (
    <div className='row justify-content-center text-center !mb-10 !mt-10'>
        <FormControl 
            sx={{ m: 1, width: '25ch', input: { height: '40px', fontSize: 30 } }} 
            variant='standard'
            style={{ width: '90%' }}
        >
        <InputLabel 
            htmlFor='standard-adornment' 
            style={{
            color: props.theme.palette.mode === 'dark' ? props.theme.palette.common.white : props.theme.palette.grey[900], 
            fontSize: 20 
            }}
        >
            {props.label}
        </InputLabel>
        <Input
            id='standard-adornment'
            value={props.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                props.setValue(e.target.value)
            }} 
            style={{
                color: props.theme.palette.mode === 'dark' ? props.theme.palette.common.white : props.theme.palette.grey[900]
            }}
            sx={{
            ':after': {
                borderBottomColor: props.theme.palette.mode === 'dark' ? props.theme.palette.common.white : props.theme.palette.grey[900]
            }
            }}
            endAdornment={
                <>
                    {
                        props.onClick ? (
                            <InputAdornment position='end'>
                                <IconButton
                                    style={{ 
                                        color: props.theme.palette.mode === 'dark' ? props.theme.palette.common.white : props.theme.palette.grey[900]
                                    }}
                                    onClick={() => props.onClick && props.onClick()}
                                    className='!p-5'
                                    edge='end'
                                >
                                    <SendIcon/>
                                </IconButton>
                            </InputAdornment>
                        ) : (
                            <>
                            </>
                        )
                    }
                </>
            }
        />
        </FormControl>
    </div>
  );
}
