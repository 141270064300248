import { Theme } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

interface CreationItemLoaderParams {
    theme: Theme;
    style?: object | undefined
}

export const CreationItemLoader = (params: CreationItemLoaderParams): JSX.Element => {
    return <Skeleton
        sx={{ 
            backgroundColor: params.theme.palette.mode === 'dark' ? params.theme.palette.grey[900] : params.theme.palette.grey[500] 
        }} 
        style={{ ...params.style }}
        variant="rectangular" 
        className='!inline-grid !ml-2 !mr-2 !mt-2 !mb-2 !w-72 !rounded-2xl !h-72'
        width={210}
        height={118}
        animation="wave" 
    />;
}