import { IRouteProps } from '../../common/dto/routeProps';
import { Index as PublicRentsCreate } from './public';

export default function Index(props: IRouteProps) {
  return (
    <>
      {
          props.network && 
          <>
              {
                  props.network.PUBLIC ? (
                      <PublicRentsCreate {...props} />
                  ) : (
                    <>
                    </>
                  )
              }
          </>
      }
    </>
  );
}
