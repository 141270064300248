import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { listOne } from '../../common/methods/listOne';
import { PrivateRoute } from '../../common/dto/routeProps';
import { IEvmPrivateSale } from '../../common/dto/models/private/evmSale';


export const Index = (props: PrivateRoute) => {
    const [item, setItem] = useState<IEvmPrivateSale | null>(null);
    const { id } = useParams();
    const navigate = useNavigate();


    useEffect(() => {
        try {
            const fetch = async () => {
                if ( id && props.network ) {
                    await listOne(
                        props.network.API_URL,
                        props.category,
                        props.network.CHAIN_ID,
                        id,
                        props.setError,
                        setItem
                    );
                }
            }
            fetch();
        } catch (err) {
            props.setError('Internal error, try again later!');
        }
    }, [id, props.network]);


    const cancel = () => {

    }

    const buy = () => {

    }

    return (
        <>
        </>
    );
}