import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Theme } from '@mui/material';


export interface IPublicCreatorCard {
  category: string;
  theme: Theme
}

export const CreatorCard = (props: IPublicCreatorCard) => {
    const navigate = useNavigate();
    return (
      <div 
        className='!inline-grid !ml-4 !mr-4 !mb-4 !rounded-2xl !cursor-pointer border-2 !border-dashed shadow-xl content-start' 
        style={{ 
          borderColor: props.theme.palette.mode === 'dark' ? props.theme.palette.common.white : props.theme.palette.common.black, 
          width: '18rem',
          height: '18rem'
        }}
        onClick={() => navigate('/' + props.category + '/create')}
      >
        <AddIcon 
          style={{ 
            color: props.theme.palette.mode === 'dark' ? props.theme.palette.common.white : props.theme.palette.common.black
          }} 
          className='!h-72 !w-72 !rounded-2xl'
        />
      </div>
    )
}