import { useState, useEffect } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { toWei } from 'web3-utils';
import { address0x0 } from '../../app/utility';
import { ContractTransaction } from 'ethers';
import { PublicAssetsHandler } from '../../common/create/public/publicAssetsHandler';
import { triggerCreationPublicAssets, TriggerCreationPublicAssetsOutput } from '../../common/methods/triggerCreationPublicAssets';
import { getCreationPublicAssets } from '../../common/methods/getCreationPublicAssets';
import { handlePublicCreateTransactionReceipt } from '../../common/create/public/handlePublicTransactionReceipt';
import { RequiredApproval } from '../../common/create/public/requiredApproval';
import { PublicRoute } from '../../common/dto/routeProps';
import { TextInput } from '../../common/inputs/textInput';
import { handlePriceInput } from '../../common/methods/handlePriceInput';


export const Index = (props: PublicRoute) => {
  const navigate: NavigateFunction = useNavigate();
  const [assets, setAssets] = useState<Array<any>>([]);
  const [receivedAssetsResponse, setReceivedAssetsResponse] = useState<boolean>(false);
  const [price, setPrice] = useState<string>('');
  const [requiredApprovals, setRequiredApprovals] = useState<Array<RequiredApproval>>([]);
  const [solvedRequiredApprovals, setSolvedRequiredApprovals] = useState<Array<RequiredApproval>>([]);

  useEffect(() => {

      getCreationPublicAssets({
        accounts: props.accounts,
        network: props.network,
        setAssets: setAssets,
        setError: props.setError,
        setRequestFinished: setReceivedAssetsResponse
      });

  }, []);

  const create = async () => {
    try {

      if ( Number(price) <= 0 ) {
        props.setError("Invalid price, must be greater than 0");
        setPrice('');
        return;
      }

      console.log(assets);

      const formattedAndVerifiedAssets: TriggerCreationPublicAssetsOutput = await triggerCreationPublicAssets({
        assets: assets,
        network: props.network,
        setApprovals: setRequiredApprovals,
        setError: props.setError,
        setSolvedApprovals: setSolvedRequiredApprovals,
        signer: props.signer,
        contractAddressToApprove: props.network.SALES.address
      });

      const tx: ContractTransaction = await props.network.SALES
      .connect(props.signer)
      .sell(
        formattedAndVerifiedAssets.contractAddresses, // nft addresses
        formattedAndVerifiedAssets.assetIds, // nft ids
        formattedAndVerifiedAssets.assetAmounts, // nft amounts
        formattedAndVerifiedAssets.assetTypes, // nft types
        toWei(price, 'ether'), // price
        address0x0, // currency
        {
          gasLimit: props.network.GAS_LIMIT[12]
        }
      );
      
      await handlePublicCreateTransactionReceipt({
        navigate: navigate,
        eventName: "NewSale",
        setError: props.setError,
        tx: tx
      });

    } catch (err: any) {
      console.error(Error(err).message);
    }
  }

  return (
    <PublicAssetsHandler 
      {...props} 
      assets={assets} setAssets={setAssets} 
      receivedAssetsResponse={receivedAssetsResponse} setReceivedAssetsResponse={setReceivedAssetsResponse} 
      requiredApprovals={requiredApprovals} setRequiredApprovals={setRequiredApprovals} 
      solvedRequiredApprovals={solvedRequiredApprovals} setSolvedRequiredApprovals={setSolvedRequiredApprovals}
      child={
        <TextInput 
          onClick={create} 
          setValue={(e: string) => handlePriceInput(e, setPrice)} 
          theme={props.theme} 
          label={"Price (in " + props.network.NATIVE_CURRENCY.name + ")"} 
          value={price}
        />
      }
    />
  );
}
