import { ConfigurableCard } from '../../cards/public/configurable';
import { toHex } from 'web3-utils';
import { IPublicAssetsHandler } from './assetsHandler';
import { RequiredApproval } from './requiredApproval';
import { generator } from '../../skeletons/generator';


export const PublicAssetsHandler = (props: IPublicAssetsHandler) => {

  const handleAssetsQuantity = (tokenId: string | number, amount: number, contractAddress: string) => {
    
    props.setAssets(props.assets.map((asset: any) => {

      if ( tokenId === asset.tokenId && contractAddress === asset.tokenAddress ) {

        let updatedQuantity = asset.quantity + amount;

        if ( updatedQuantity >= 0 && updatedQuantity <= Number(asset.amount) ) {
          asset.quantity = updatedQuantity;
        }
        
      }

      return asset;

    }));

  }

  return (
    <>
      <div 
        className='row mt-10 ml-2 mr-2' 
        style={{ 
          display: 'flex', 
          justifyContent: 'center'
        }}
      >
        <div 
          className='p-4 w-full max-w-md rounded-lg border shadow-md sm:p-8'
          style={{
            borderColor: props.theme.palette.mode === 'dark' ? props.theme.palette.grey[900] : props.theme.palette.common.white
          }}
        >
          <div className='flex items-center mb-4 justify-center text-center'>
            <h5 className='text-xl font-bold leading-none'>Trade creation</h5>
          </div>
          <div className='flow-root'>
            <ul role='list' className='divide-y divide-gray-200 dark:divide-gray-700'>
                <li className='py-3 sm:py-4'>
                    <div className='flex items-center space-x-4'>
                        <div className='flex-1 min-w-0'>
                          <p 
                            className='text-sm font-medium truncate'
                            style={{
                              color: props.theme.palette.mode === 'dark' ? props.theme.palette.common.white : props.theme.palette.common.black
                            }}  
                          >
                            1. Select the assets
                          </p>
                          <p className='text-sm text-gray-500 truncate dark:text-gray-400 whitespace-normal'>
                            Select which assets you want to trade from the list of the assets you won, displayed below.
                          </p>
                        </div>
                        <div className='inline-flex items-center text-base font-semibold text-gray-900'>
                          
                        </div>
                    </div>
                </li>
                <li className='py-3 sm:py-4'>
                    <div className='flex items-center space-x-4'>
                        <div className='flex-1 min-w-0'>
                          <p 
                            className='text-sm font-medium truncate'
                            style={{
                              color: props.theme.palette.mode === 'dark' ? props.theme.palette.common.white : props.theme.palette.common.black
                            }}
                          >
                            2. Set the price
                          </p>
                          <p className='text-sm text-gray-500 truncate dark:text-gray-400 whitespace-normal'>
                            Set the price you consider to be the best fit for the trade
                          </p>
                        </div>
                        <div className='inline-flex items-center text-base font-semibold text-gray-900 dark:text-white'>
                            
                        </div>
                    </div>
                </li>
                <li className='py-3 sm:py-4'>
                    <div className='flex items-center space-x-4'>
                        <div className='flex-1 min-w-0'>
                          <p 
                            className='text-sm font-medium truncate' 
                            style={{
                              color: props.theme.palette.mode === 'dark' ? props.theme.palette.common.white : props.theme.palette.common.black
                            }}  
                          >
                            3. Press the send button
                          </p>
                          <p className='text-sm text-gray-500 truncate dark:text-gray-400 whitespace-normal'>
                            Press the create button and finish the trade creation. You might be required to approve your assets to be traded.
                          </p>
                        </div>
                        <div className='inline-flex items-center text-base font-semibold text-gray-900 dark:text-white'>
                          
                        </div>
                    </div>
                </li>
            </ul>
          </div>
        </div>
      </div>
            
      {props.child}

      <div style={{ textAlign: 'center' }}>
        {
          props.receivedAssetsResponse ? (
            <>
              {
                props.assets.map((asset: any, id: number) => 
                  <div key={id} className="inline">
                    {
                      asset.metadata && 
                      <>
                        <ConfigurableCard
                          item={asset}
                          image={asset.metadata.image}
                          onClick={handleAssetsQuantity}
                          requiredApproval={props.requiredApprovals.filter((approval: RequiredApproval) => approval.contractAddress === asset.tokenAddress && toHex(asset.tokenId) === approval.id)[0]}
                          solvedRequiredApprovals={props.solvedRequiredApprovals.filter((approval: RequiredApproval) => approval.contractAddress === asset.tokenAddress && toHex(asset.tokenId) === approval.id)[0]}
                          theme={props.theme}
                        />
                      </>
                    }
                  </div>
                )
              }
            </>
          ) : (
            <>
              {
                generator(
                  18, 
                  props.theme, 
                  {
                    width: 230,
                    height: 210, 
                    maxHeight: 210,
                    minWidth: 240,
                    maxWidth: 240
                  }
                )
              }
            </>
          ) 
        }
      </div>

    </>
  );
}
