import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


export interface SnackbarAlertProps {
    message: string | null,
    open: string | null,
    autoHideDuration: number,
    onClose: Function,
    severity: number
}

export default function SnackbarAlert(props: SnackbarAlertProps) {
    const snackbarPosition: SnackbarOrigin = {
        vertical: 'bottom',
        horizontal: 'right',
    };
    const { vertical, horizontal } = snackbarPosition;
    return <>
        {
            props.message && 
            <Snackbar 
                ContentProps={{
                    style: {
                        backgroundColor: props.severity === 2 ? 'green' : 'red', 
                        color: 'white',
                        borderRadius: '10px',
                        padding: '0.8rem',
                        justifyContent: 'center',
                        textAlign: 'center',
                        verticalAlign: 'center',
                        wordBreak: 'break-word',
                        maxWidth: '6rem'
                    }
                }}
                anchorOrigin={{ vertical, horizontal }}
                open={Boolean(props.open)} 
                autoHideDuration={props.autoHideDuration} 
                onClose={() => props.onClose()}
                message={
                    <>
                        {props.message}
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.5, paddingRight: '0.9rem' }}
                            onClick={() => props.onClose()}
                        >
                            <CloseIcon />
                        </IconButton>
                    </>
                }
            />
        }
    </>;
}