import { formatImageUrl } from '../../../app/utility';
import { ListCard } from './card';


export interface PublicRecipeReviewCardParams {
  item: any,
  category: string
}

export default function RecipeReviewCard(props: PublicRecipeReviewCardParams) {

  return (
      <>
          {
              props.item.items && 
              <>
                  {
                      props.item.items.length > 0 && 
                      <>
                        {
                          props.item.items[0] && 
                          <>
                            {
                              props.item.items[0].metadata && 
                              <ListCard 
                                item={props.item}
                                image={formatImageUrl(props.item.items[0].metadata.metadata.image)} 
                                category={props.category}
                              />
                            }
                          </>
                        }
                      </>
                  }
              </>
          }
      </>
  );
}
