import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { fromWei } from 'web3-utils';
import { INativeCurrency } from '../../../app/utility';
import { address0x0 } from '../../../app/utility';
import { StatStyle } from './statStyle';
import { Theme } from '@mui/material/styles';


export interface PublicViewStats {
  price: string;
  currency: string;
  nativeCurrency: INativeCurrency;
  theme: Theme;
}

export const PublicViewStats = (props: PublicViewStats) => {
    return (
        <div className='grid grid-cols-1 divide-y'>
            <div className='px-6 py-4 mb-5'>
                <div className='grid-cols-2 justify-content-center text-center break-words'>
                    <StatStyle 
                        theme={props.theme}
                        child={
                            <div className='justify-center !text-center font-black text-lg'>
                                <LocalAtmIcon sx={{ verticalAlign: 'text-bottom' }}/>
                                <span className="ml-2 mr-5">Price: </span>
                                <span>
                                    {fromWei(props.price,'ether')}
                                </span>
                                <span className='ml-2'>
                                    {
                                        address0x0 ? (
                                        <>
                                            {
                                                address0x0 === props.currency ? (
                                                    <>
                                                        {props.nativeCurrency.name}
                                                    </>
                                                ) : (
                                                    <>
                                                        {props.nativeCurrency.name}
                                                    </>
                                                )
                                            }
                                        </>
                                        ) : (
                                            <>UNKNOWN ASSET</>
                                        )
                                    }
                                </span>
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    );
}