import { ListCard } from './card';


export interface PrivateRecipeReviewCardParams {
  item: any,
  category: string
}

export default function RecipeReviewCard(props: PrivateRecipeReviewCardParams) {
  return (
      <>
          {
              props.item.items && 
              <>
                  {
                      props.item.items.length > 0 && 
                      <>
                        {
                          props.item.items[0] && 
                          <>
                            {/*
                              props.item.items[0].metadata && 
                              <ListCard 
                                item={props.item}
                                image={props.item.items[0].metadata[0].nft_data[0].external_data ? props.item.items[0].metadata[0].nft_data[0].external_data.image : ''} 
                                category={props.category}
                              />
                            */}
                          </>
                        }
                      </>
                  }
              </>
          }
      </>
  );
}
