import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import { listOne } from '../../common/methods/listOne';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PercentIcon from '@mui/icons-material/Percent';
import { address0x0 } from '../../app/utility';
import { BigNumber } from 'ethers';
import { fromWei } from 'web3-utils';
import { RENTS as PRIVATE_RENTS } from '../../../contracts/private/typechain-types/Rents.sol/RENTS';
import { IPublicRentAsset } from '../../common/dto/assets/publicRentAsset';
import { PrivateRoute } from '../../common/dto/routeProps';


export const Index = (props: PrivateRoute) => {
    const optionButtonsStyle = { color: '#201F29' , borderColor: '#201F29' };
    const [item, setItem] = useState<IPublicRentAsset>();
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        try {
            const fetch = async () => {
                if ( id && props.network ) {
                    await listOne(
                        props.network.API_URL,
                        props.category,
                        props.network.CHAIN_ID,
                        id,
                        props.setError,
                        setItem
                    );
                }
            }
            fetch();
        } catch (err) {
            props.setError('Internal error, try again later!');
        }
    }, [id, props.network]);


    const cancel = async () => {

    }

    const finish = async () => {



    }

    const borrow = async () => {
        try {
            if ( item && props.network ) {
                (props.network.RENTS as PRIVATE_RENTS).rentItem(BigNumber.from(item.tokenId));
            }
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <>
        </>
    );
}