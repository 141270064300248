import { useNavigate } from 'react-router-dom';
import { Theme } from '@mui/material';


export interface IPrivateCreatorCard {
  category: string;
  theme: Theme
}

export const CreatorCard = (props: IPrivateCreatorCard) => {
    const navigate = useNavigate();
    return (
      <div 
        className='!inline-grid !ml-4 !mr-4 !mb-4 !rounded-2xl !cursor-pointer shadow-xl content-start' 
        style={{ 
          width: '18rem',
          height: '18rem'
        }}
        onClick={() => navigate('/' + props.category + '/create')}
      >
        <img 
          src={'/creators/create' + props.category + '.jpg'} 
          className='!h-72 !w-72 !rounded-2xl' 
        />
      </div>
    )
}