import { httpSimpleGetRequest, IPrivateNetwork } from "../../app/utility";


export interface IGetCreationPrivateAssetsParams {
    network: IPrivateNetwork,
    accounts: Array<string>,
    setError: Function,
    setRequestFinished: Function,
    setAssets: Function
}

export const getCreationPrivateAssets = async (
    props: IGetCreationPrivateAssetsParams
) => {

    try {

        const theAssets = await httpSimpleGetRequest(props.network.API_URL + '/' + props.network.CHAIN_ID + '/assets/' + props.accounts[0]);

        if ( theAssets ) {

            props.setRequestFinished(true);
            if ( theAssets.errorCode !== undefined && theAssets.errorMessage !== undefined ) {

                props.setError(theAssets.errorMessage);

                if ( theAssets.errorCode === null && theAssets.errorMessage === null ) {

                let formattedAssets = [];
                for ( let i = 0 , l = theAssets.entities.length ; i < l ; ++i ) {

                    if ( theAssets.entities[i].type === 'nft' ) {

                    for ( let j = 0 , k = theAssets.entities[i].nft_data.length ; j < k ; ++j ) {
                        formattedAssets.push({
                        ...theAssets.entities[i].nft_data[j],
                        quantity: 0,
                        contractAddress: theAssets.entities[i].contract_address
                        });
                    }

                    }

                }

                props.setAssets(formattedAssets);

                }

            }

        }

    } catch (err) {
        props.setError('Internal error, try again later!');
    }

}