import { useState, useEffect } from 'react';
import { httpSimpleGetRequest } from '../../app/utility';
import { PrivateAssetsHandler } from '../../common/create/private/privateAssetHandler';
import { PrivateRoute } from '../../common/dto/routeProps';
import { TextInput } from '../../common/inputs/textInput';


export const Index = (props: PrivateRoute) => {
  const [assets, setAssets] = useState<Array<any>>([]);
  const [receivedAssetsResponse, setReceivedAssetsResponse] = useState<boolean>(false);
  const [price, setPrice] = useState<string>('');


  useEffect(() => {
    try {
      const fetch = async () => {
        if ( props.network ) {
          const assets = await httpSimpleGetRequest(props.network.API_URL + "/" + props.network.CHAIN_ID + "/assets/" + props.accounts[0]);
          if ( assets ) {
            setReceivedAssetsResponse(true);
            if ( assets.errorCode !== undefined && assets.errorMessage !== undefined ) {
                props.setError(assets.errorMessage);
                if ( assets.errorCode === null && assets.errorMessage === null ) {
                    setAssets(assets.entities);
                }
            }
          }
        }
      }
      fetch();
    } catch (err) {
        props.setError("Internal error, try again later!");
    }
  }, []);

  const create = async () => {

    if ( Number(price) <= 0 ) {
      props.setError("Invalid price, must be greater than 0");
      setPrice('');
      return;
    }

    props.network.SALES.createSale(
        1, // game
        1, // item
        1, // server
        1, // price
        false, // premium
        {
          from: props.accounts[0]
        }
    );

  }

  return (
    <>
    </>
  );
}
