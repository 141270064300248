import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import GavelIcon from '@mui/icons-material/Gavel';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { listOne } from '../../common/methods/listOne';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { address0x0 } from '../../app/utility';
import { BigNumber, ContractReceipt, ContractTransaction } from 'ethers';
import { toHex, toBN, fromWei } from 'web3-utils';
import { AUCTIONS as PUBLIC_AUCTIONS } from '../../../contracts/public/typechain-types/src/auctions/Template.sol/AUCTIONS';
import { PublicRoute } from '../../common/dto/routeProps';
import { PublicViewImage } from '../../common/cards/public/viewImage';
import { PublicViewStats } from '../../common/cards/public/viewStats';
import { Divider } from '@mui/material';
import { StatStyle } from '../../common/cards/public/statStyle';
import { ViewButton } from '../../common/buttons/viewButton';
import { IPublicAuctionAsset } from '../../common/dto/assets/publicAuctionAsset';
import { generator } from '../../common/skeletons/generator';


export const Index = (props: PublicRoute) => {
    const [item, setItem] = useState<IPublicAuctionAsset | null>(null);
    const [currentAssetIndex, setCurrenAssetIndex] = useState(0);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        try {
            const fetch = async () => {
                if ( id && props.network ) {
                    await listOne(
                        props.network.API_URL,
                        props.category,
                        props.network.CHAIN_ID,
                        id,
                        props.setError,
                        setItem
                    );
                }
            }
            fetch();
        } catch (err) {
            props.setError('Internal error, try again later!');
        }
    }, []);


    const cancel = async () => {
        try {
            if ( item && props.network ) {
                const tx: ContractTransaction = await props.network.AUCTIONS
                .connect(props.signer)
                .cancel(BigNumber.from(item.tokenId));
                tx.wait().then((receipt: ContractReceipt) => {
                    setItem({
                        ...item,
                        status: 3
                    });
                    props.setSuccess("Auction has been successfully cancelled!");
                });
            }
        } catch (err) {
            console.error(err);
        }
    }

    const bid = async () => {
        try {
            if ( item && props.network ) {
                const totalToPay = toHex(toBN(item.price).add(toBN(item.tax)));
                console.log("1 >> ", item.price, item.tax);
                const tx: ContractTransaction = await props.network.AUCTIONS
                .connect(props.signer)
                .bid(BigNumber.from(item.tokenId),{
                    value: totalToPay
                });
                tx.wait().then((receipt: ContractReceipt) => {
                    setItem({
                        ...item,
                        currentBid: item.price,
                        price: String(BigNumber.from(item.price).add(item.tax)),
                        winner: props.accounts[0],
                        status: 1
                    });
                    props.setSuccess("You have successfully placed the bid!");
                });
            }
        } catch (err) {
            console.error(err);
        }
    }

    const finish = async () => {
        try {
            if ( item && props.network ) {
                const tx: ContractTransaction = await props.network.AUCTIONS
                .connect(props.signer)
                .finish(BigNumber.from(item.tokenId));
                tx.wait().then((receipt: ContractReceipt) => {
                    setItem({
                        ...item,
                        status: 2
                    });
                    props.setSuccess("Auction has been successfully finished!");
                });
            }
        } catch (err) {
            console.error(err);
        }
    }

    const buttons = (item: any) => {
        return <div className='grid grid-cols-1 divide-y mt-5'>
            <div className='px-6 py-4'>
                <div className='grid grid-cols-3 justify-content-center text-center'>
                    <div>
                        {
                            item.owner && props.accounts[0] && 
                            <>
                                {
                                    item.owner.toLowerCase() === props.accounts[0].toLowerCase() && ( item.status === 0 || item.status === 1 ) && 
                                    <StatStyle 
                                        theme={props.theme}
                                        child={
                                            <ViewButton
                                                theme={props.theme}
                                                label="Close"
                                                onClick={cancel}
                                            />
                                        }
                                    />
                                }
                            </>
                        }
                    </div>
                    <div>
                        {
                            item.owner && props.accounts[0] && 
                            <>
                                {
                                    ( item.status === 0 || item.status === 1 ) && ( item.winner === undefined || item.winner.toLowerCase() !== props.accounts[0].toLowerCase() ) && item.owner.toLowerCase() !== props.accounts[0].toLowerCase() && 
                                    <StatStyle 
                                        theme={props.theme}
                                        child={
                                            <ViewButton
                                                theme={props.theme}
                                                label="Bid"
                                                onClick={bid}
                                            />
                                        }
                                    />
                                }
                            </>
                        }
                    </div>
                    <div>
                        {
                            item.owner && props.accounts[0] && 
                            <>
                                {
                                    item.owner.toLowerCase() === props.accounts[0].toLowerCase() && item.status === 1 && 
                                    <StatStyle 
                                        theme={props.theme}
                                        child={
                                            <ViewButton
                                                theme={props.theme}
                                                label="Finish"
                                                onClick={finish}
                                            />
                                        }
                                    />
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>;
    }

    return (
        <div className='p-10 flex justify-center items-center' style={{ marginTop: '180px' }}>
            {
                item ? (
                    <>
                        <div className='max-w-2xl rounded-3xl overflow-hidden !shadow-lg'>
                            <PublicViewImage 
                                url={item.items[0].metadata.metadata.image} 
                                explorer={props.network.EXPLORER}
                                description={item.items[0].metadata.metadata.description}
                                name={item.items[0].metadata.name}
                                owner={item.items[0].metadata.ownerOf}
                                setCurrenAssetIndex={setCurrenAssetIndex}
                                totalItems={item.items.length}
                                currentAssetIndex={currentAssetIndex}
                            />
                            <PublicViewStats 
                                theme={props.theme}
                                price={item.price} 
                                currency={item.currency} 
                                nativeCurrency={props.network.NATIVE_CURRENCY}
                            />
                            <Divider/>
                            {buttons(item)}
                        </div>
                    </>
                ) : (
                    <div className='max-w-2xl rounded-3xl overflow-hidden !shadow-lg w-full w-100 h-100'>
                        {
                            generator(
                                1, 
                                props.theme, 
                                {
                                    'minWidth': '100%',
                                    'minHeight': '42rem'
                                }
                            )
                        }
                        {
                            generator(
                                1, 
                                props.theme, 
                                {
                                    'minWidth': '100%',
                                    'minHeight': '4rem'
                                }
                            )
                        }
                    </div>
                )
            }
        </div>
    );
}