/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  IDiscounts,
  IDiscountsInterface,
} from "../../../contracts/plugins/IDiscounts";

const _abi = [
  {
    inputs: [
      {
        internalType: "uint8",
        name: "_tokenType",
        type: "uint8",
      },
      {
        internalType: "address",
        name: "_tokenContract",
        type: "address",
      },
      {
        internalType: "uint8",
        name: "_discount",
        type: "uint8",
      },
      {
        internalType: "uint256[]",
        name: "_tokenIds",
        type: "uint256[]",
      },
    ],
    name: "addDiscount",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "requester",
        type: "address",
      },
    ],
    name: "calculateDiscount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_discountId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_tokenContract",
        type: "address",
      },
      {
        internalType: "uint8",
        name: "_discount",
        type: "uint8",
      },
      {
        internalType: "uint256[]",
        name: "_tokenIds",
        type: "uint256[]",
      },
      {
        internalType: "uint8",
        name: "_tokenType",
        type: "uint8",
      },
    ],
    name: "editDiscount",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_discountId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "tokenIdIndex",
        type: "uint256",
      },
    ],
    name: "getDiscountTokenId",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_discountId",
        type: "uint256",
      },
    ],
    name: "getDiscountTokenIds",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class IDiscounts__factory {
  static readonly abi = _abi;
  static createInterface(): IDiscountsInterface {
    return new utils.Interface(_abi) as IDiscountsInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IDiscounts {
    return new Contract(address, _abi, signerOrProvider) as IDiscounts;
  }
}
