export const ETHEREUM_GOERLI_AUCTIONS = '0xC469cff0D5e716269D3099125Fae2BA1273D04f5';
export const ETHEREUM_GOERLI_RENTS = '0xbECc73a2E093C977DB871fA88354240C0eB71351';
export const ETHEREUM_GOERLI_SALES = '0xed8da69A13Df670ceE05a4008dA86a05C3B2009b';

export const POLYGON_MAINNET_AUCTIONS = '0xD09BD53bB45648cbB556488167915C8a8024c1F1';
export const POLYGON_MAINNET_RENTS = '0xF58F2b7699feA932975282e1DFb8230598bA884B';
export const POLYGON_MAINNET_SALES = '0x60D3cc9c79c18790F6532e8365E9b2F112f7ef52';

export const POLYGON_MUMBAI_AUCTIONS = '0x6aeBB7f8c6F744E1d47ad0AE2A0C5717AE9119D2';
export const POLYGON_MUMBAI_RENTS = '0x1922B4Dcb6576268c3750dd9274b12abE2093A59';
export const POLYGON_MUMBAI_SALES = '0x4D28a253405Ad6E6dc109653f3DEcD5C1ec9d140';

export const BINANCE_SMARTCHAIN_TESTNET_AUCTIONS = '0xeFeB507A21f518c49e7A64B3a6d45032D7012B34';
export const BINANCE_SMARTCHAIN_TESTNET_RENTS = '0x264EF8c522C481235865cBDc88A6AF5540F7396c';
export const BINANCE_SMARTCHAIN_TESTNET_SALES = '0xa4C9afd8531Cd7b9dD5Afa64100Bcc6B42e42a8e';

export const BINANCE_SMARTCHAIN_MAINNET_AUCTIONS = '0x3E1Af0B942d454576A7594cBcFf191D8CB1fd3f8';
export const BINANCE_SMARTCHAIN_MAINNET_RENTS = '0xc613fF42EcE58B3eE4dE840A5430874358Ba4b86';
export const BINANCE_SMARTCHAIN_MAINNET_SALES = '0x43605384bD67201274c5EBd93a21909DD6087Da6';