import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PendingIcon from '@mui/icons-material/Pending';
import VerifiedIcon from '@mui/icons-material/Verified';
import { styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import { RequiredApproval } from '../../create/public/requiredApproval';
import { Theme } from '@mui/material/styles';
import { formatImageUrl } from '../../../app/utility';


export interface ConfigurablePublicCardParams {
  item: any,
  image: string,
  onClick: Function,
  requiredApproval?: RequiredApproval,
  solvedRequiredApprovals?: RequiredApproval,
  theme: Theme
}

const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  padding: theme.spacing(1),
}));

export const ConfigurableCard = (params: ConfigurablePublicCardParams) => {
  return (
    <div style={{
      width: 250, 
      backgroundColor: params.theme.palette.mode === 'dark' ? params.theme.palette.grey[900] : params.theme.palette.common.white, 
      color: params.theme.palette.mode === 'dark' ? params.theme.palette.common.white : params.theme.palette.common.black, 
      margin: '0.5rem', 
      display: 'inline-flex'
    }} >
      <CardMedia
        component='img'
        image={formatImageUrl(params.image)} 
        alt={params.item.name}
        sx={{ height: 210, width: '100%', maxHeight: 210, maxWidth: 180 }}
      />
      <CardActions disableSpacing sx={{ display: 'block', textAlign: 'center', justifyContent: 'center' }}>
        <IconButton aria-label='add to favorites'>
          <IconButton onClick={() => params.onClick(params.item.tokenId, 1, params.item.tokenAddress)}>
            <ArrowDropUpIcon />
          </IconButton>
        </IconButton>
        <IconButton aria-label='share'>
          <IconButton onClick={() => params.onClick(params.item.tokenId, -1, params.item.tokenAddress)}>
            <ArrowDropDownIcon />
          </IconButton>
        </IconButton>
        {
          params.item.quantity > 0 && 
          <>
            <Div>
              <span style={{ fontWeight: 200 }} className='lowercase'>
                x
              </span>
              <span style={{ fontWeight: 900 }} className='text-lg'>
                {params.item.quantity}
              </span>
            </Div>
            <Div>
              {
                params.requiredApproval ? (
                  <>
                    {
                      params.requiredApproval.validated === false ? (
                        <PendingIcon 
                          sx={{
                            color: params.theme.palette.mode === 'dark' ? params.theme.palette.grey[700] : params.theme.palette.common.white
                          }}
                        />
                      ) : (
                        <>
                          {
                            params.solvedRequiredApprovals ? (
                              <>
                                {
                                  params.requiredApproval.validated === true ? (
                                    <VerifiedIcon sx={{ color: green[500] }}/>
                                  ) : (
                                    <PendingIcon/>
                                  )
                                }
                              </>
                            ) : (
                              <PendingIcon/>
                            )
                          }
                        </>
                      )
                    }
                  </>
                ) : (
                  <>
                  </>
                ) 
              }
            </Div>
          </>
        }
      </CardActions>
    </div>
  )
}