import axios  from 'axios';

import { RENTS__factory as PublicRentsFactory } from '../../contracts/public/typechain-types/factories/src/rents/Template.sol/RENTS__factory';
import { AUCTIONS__factory as PublicAuctionsFactory } from '../../contracts/public/typechain-types/factories/src/auctions/Template.sol/AUCTIONS__factory';
import { SALES__factory as PublicSalesFactory } from '../../contracts/public/typechain-types/factories/src/sales/Template.sol/SALES__factory';
import { SALES__factory as PrivateSalesFactory } from '../../contracts/private/typechain-types/factories/Sales.sol/SALES__factory';
import { AUCTIONS__factory as PrivateAuctionsFactory } from '../../contracts/private/typechain-types/factories/Auctions.sol/AUCTIONS__factory';
import { RENTS__factory as PrivateRentsFactory } from '../../contracts/private/typechain-types/factories/Rents.sol/RENTS__factory';
import { LOTTERIES__factory as PrivateLotteriesFactory } from '../../contracts/private/typechain-types/factories/Lotteries.sol/LOTTERIES__factory';
import { STORAGE__factory as PrivateStorageFactory } from '../../contracts/private/typechain-types/factories/Storage.sol/STORAGE__factory';
import { ITEMBETS__factory as PrivateItemBetsFactory } from '../../contracts/private/typechain-types/factories/ItemBets.sol/ITEMBETS__factory';
import { EXCHANGES__factory as PrivateExchangesFactory } from '../../contracts/private/typechain-types/factories/Exchanges.sol/EXCHANGES__factory';
import { AUCTIONS as PRIVATE_AUCTIONS } from '../../contracts/private/typechain-types/Auctions.sol/AUCTIONS';
import { RENTS as PRIVATE_RENTS } from '../../contracts/private/typechain-types/Rents.sol/RENTS';
import { SALES as PRIVATE_SALES } from '../../contracts/private/typechain-types/Sales.sol/SALES';
import { LOTTERIES as PRIVATE_LOTTERIES } from '../../contracts/private/typechain-types/Lotteries.sol/LOTTERIES';
import { STORAGE as PRIVATE_STORAGE } from '../../contracts/private/typechain-types/Storage.sol/STORAGE';
import { ITEMBETS as PRIVATE_ITEMBETS } from '../../contracts/private/typechain-types/ItemBets.sol/ITEMBETS';
import { EXCHANGES as PRIVATE_EXCHANGES } from '../../contracts/private/typechain-types/Exchanges.sol/EXCHANGES';
import { RENTS as PUBLIC_RENTS } from '../../contracts/public/typechain-types/src/rents/Template.sol/RENTS';
import { SALES as PUBLIC_SALES } from '../../contracts/public/typechain-types/src/sales/Template.sol/SALES';
import { AUCTIONS as PUBLIC_AUCTIONS } from '../../contracts/public/typechain-types/src/auctions/Template.sol/AUCTIONS';
import { BINANCE_SMARTCHAIN_MAINNET_AUCTIONS, BINANCE_SMARTCHAIN_MAINNET_RENTS, BINANCE_SMARTCHAIN_MAINNET_SALES, BINANCE_SMARTCHAIN_TESTNET_AUCTIONS, BINANCE_SMARTCHAIN_TESTNET_RENTS, BINANCE_SMARTCHAIN_TESTNET_SALES, ETHEREUM_GOERLI_AUCTIONS, ETHEREUM_GOERLI_RENTS, ETHEREUM_GOERLI_SALES, POLYGON_MAINNET_AUCTIONS, POLYGON_MAINNET_RENTS, POLYGON_MAINNET_SALES, POLYGON_MUMBAI_AUCTIONS, POLYGON_MUMBAI_RENTS, POLYGON_MUMBAI_SALES } from '../common/params/contracts';

export const address0x0: string = '0x0000000000000000000000000000000000000000';
/* BLOCKCHAINS CONFIGURATION */

/* URLS */
export let DOMAIN: string;
export let API_URL: string;

export interface INativeCurrency {
    name: string, 
    decimals: number, 
    symbol: string
}

export interface ISubRoute {
    NAME: string,
    PATH: string,
    personal: boolean
}

export interface IRoute {
    NAME: string,
    PATH: string,
    CATEGORY: string,
    navbarOption: boolean,
    subroutes: Array<ISubRoute>
}

export interface IPrivateNetwork {
    CHAIN_ID: string,
    NAME: string,
    ICON: string,
    AUCTIONS: PRIVATE_AUCTIONS,
    RENTS: PRIVATE_RENTS,
    SALES: PRIVATE_SALES,
    LOTTERIES: PRIVATE_LOTTERIES,
    STORAGE: PRIVATE_STORAGE,
    ITEMBETS: PRIVATE_ITEMBETS,
    EXCHANGES: PRIVATE_EXCHANGES,
    API_URL: string,
    PUBLIC: boolean,
    NATIVE_CURRENCY: INativeCurrency,
    RPC_METAMASK_PROVIDER: string,
    ROUTES: Array<IRoute>,
    SUPPORTED_ASSETS: any
}

export interface IPublicNetwork {
    CHAIN_ID: string,
    NAME: string,
    ICON: string,
    AUCTIONS: PUBLIC_AUCTIONS,
    RENTS: PUBLIC_RENTS,
    SALES: PUBLIC_SALES,
    API_URL: string,
    PUBLIC: boolean,
    GAS_LIMIT: Array<number>,
    NATIVE_CURRENCY: INativeCurrency,
    RPC_METAMASK_PROVIDER: string,
    ROUTES: Array<IRoute>,
    SUPPORTED_ASSETS: object,
    EXPLORER: string
}


if ( String(process.env.NODE_ENV) === "development" ) {

    DOMAIN = 'localhost';
    API_URL = 'http://' + DOMAIN + ':8000';

} else {

    DOMAIN = 'tradyr-evm-api.xyz';
    API_URL = 'https://' + DOMAIN;

}

export const DEFAULT_ROUTES: Array<IRoute> = [
    {
        NAME: "Sales",
        PATH: "/sales/list",
        CATEGORY: "sales",
        navbarOption: true,
        subroutes: [
            {
                NAME: "Marketplace",
                PATH: "/sales/list/marketplace",
                personal: false
            },
            {
                NAME: "Cancelled",
                PATH: "/sales/list/cancelled",
                personal: true
            },
            {
                NAME: "Sold",
                PATH: "/sales/list/sold",
                personal: true
            },
            {
                NAME: "Purchased",
                PATH: "/sales/list/purchased",
                personal: true
            }
        ]
    },{
        NAME: "Rents",
        PATH: "/rents/list",
        CATEGORY: "rents",
        navbarOption: true,
        subroutes: [
            {
                NAME: "Marketplace",
                PATH: "/rents/list/marketplace",
                personal: false
            },
            {
                NAME: "Cancelled",
                PATH: "/rents/list/cancelled",
                personal: true
            },
            {
                NAME: "Borrowed",
                PATH: "/rents/list/borrowed",
                personal: true
            },
            {
                NAME: "Rented",
                PATH: "/rents/list/rented",
                personal: true
            },
            {
                NAME: "Terminated",
                PATH: "/rents/list/terminated",
                personal: true
            }
        ]
    },{
        NAME: "Auctions",
        PATH: "/auctions/list",
        CATEGORY: "auctions",
        navbarOption: true,
        subroutes: [
            {
                NAME: "Marketplace",
                PATH: "/auctions/list/marketplace",
                personal: false
            },
            {
                NAME: "Cancelled",
                PATH: "/auctions/list/cancelled",
                personal: true
            },
            {
                NAME: "Sold",
                PATH: "/auctions/list/sold",
                personal: true
            },
            {
                NAME: "Purchased",
                PATH: "/auctions/list/purchased",
                personal: true
            }
        ]
    }
];


export const ETHEREUM_GOERLI: IPublicNetwork = {
    CHAIN_ID: '0x5',
    NAME: "Ethereum Goerli",
    ICON: window.location.origin + '/networks/ethereum.png',
    AUCTIONS: PublicAuctionsFactory.connect(ETHEREUM_GOERLI_AUCTIONS, window.ethereum.currentProvider),
    RENTS: PublicRentsFactory.connect(ETHEREUM_GOERLI_RENTS, window.ethereum.currentProvider),
    SALES: PublicSalesFactory.connect(ETHEREUM_GOERLI_SALES, window.ethereum.currentProvider),
    API_URL: API_URL,
    PUBLIC: true,
    GAS_LIMIT: [150000,200000,300000,400000,500000,600000,700000,800000,900000,1000000,1500000,2000000,3000000],
    NATIVE_CURRENCY: { name: 'Ether', decimals: 18, symbol: 'ETH' },
    RPC_METAMASK_PROVIDER: 'https://goerli.infura.io/v3/',
    ROUTES: DEFAULT_ROUTES,
    SUPPORTED_ASSETS: {},
    EXPLORER: 'https://goerli.etherscan.io'
};

export const POLYGON_MAINNET: IPublicNetwork = {
    AUCTIONS: PublicAuctionsFactory.connect(POLYGON_MAINNET_AUCTIONS, window.ethereum.currentProvider),
    RENTS: PublicRentsFactory.connect(POLYGON_MAINNET_RENTS, window.ethereum.currentProvider),
    SALES: PublicSalesFactory.connect(POLYGON_MAINNET_SALES, window.ethereum.currentProvider),
    API_URL: API_URL,
    CHAIN_ID: '0x89',
    NAME: 'Polygon Mainnet',
    ICON: window.location.origin + '/networks/polygon.png',
    GAS_LIMIT: [150000,200000,300000,400000,500000,600000,700000,800000,900000,1000000,1500000,2000000,3000000],
    PUBLIC: true,
    NATIVE_CURRENCY: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
    RPC_METAMASK_PROVIDER: 'https://rpc-mainnet.maticvigil.com',
    ROUTES: DEFAULT_ROUTES,
    SUPPORTED_ASSETS: {},
    EXPLORER: 'https://explorer-mainnet.maticvigil.com'
};

export const POLYGON_MUMBAI: IPublicNetwork = {
    CHAIN_ID: '0x13881',
    NAME: 'Polygon Mumbai',
    AUCTIONS: PublicAuctionsFactory.connect(POLYGON_MUMBAI_AUCTIONS, window.ethereum.currentProvider),
    RENTS: PublicRentsFactory.connect(POLYGON_MUMBAI_RENTS, window.ethereum.currentProvider),
    SALES: PublicSalesFactory.connect(POLYGON_MUMBAI_SALES, window.ethereum.currentProvider),
    ICON: window.location.origin + '/networks/polygon.png',
    API_URL: API_URL,
    GAS_LIMIT: [150000,200000,300000,400000,500000,600000,700000,800000,900000,1000000,1500000,2000000,3000000],
    PUBLIC: true,
    NATIVE_CURRENCY: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
    RPC_METAMASK_PROVIDER: 'https://rpc-mumbai.maticvigil.com',
    ROUTES: DEFAULT_ROUTES,
    SUPPORTED_ASSETS: {},
    EXPLORER: 'https://mumbai.polygonscan.com'
};

export const BINANCE_SMARTCHAIN_TESTNET: IPublicNetwork = {
    CHAIN_ID: '0x61',
    NAME: 'Binance SmartChain Testnet',
    AUCTIONS: PublicAuctionsFactory.connect(BINANCE_SMARTCHAIN_TESTNET_AUCTIONS, window.ethereum.currentProvider),
    RENTS: PublicRentsFactory.connect(BINANCE_SMARTCHAIN_TESTNET_RENTS, window.ethereum.currentProvider),
    SALES: PublicSalesFactory.connect(BINANCE_SMARTCHAIN_TESTNET_SALES, window.ethereum.currentProvider),
    ICON: window.location.origin + '/networks/binance.png',
    API_URL: API_URL,
    GAS_LIMIT: [150000,200000,300000,400000,500000,600000,700000,800000,900000,1000000,1500000,2000000,3000000],
    PUBLIC: true,
    NATIVE_CURRENCY: { name: 'BNB', decimals: 18, symbol: 'BNB' },
    RPC_METAMASK_PROVIDER: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    ROUTES: DEFAULT_ROUTES,
    SUPPORTED_ASSETS: {},
    EXPLORER: 'https://testnet.bscscan.com'
};

export const BINANCE_SMARTCHAIN_MAINNET: IPublicNetwork = {
    CHAIN_ID: '0x38',
    NAME: 'Binance SmartChain',
    AUCTIONS: PublicAuctionsFactory.connect(BINANCE_SMARTCHAIN_MAINNET_AUCTIONS, window.ethereum.currentProvider),
    RENTS: PublicRentsFactory.connect(BINANCE_SMARTCHAIN_MAINNET_RENTS, window.ethereum.currentProvider),
    SALES: PublicSalesFactory.connect(BINANCE_SMARTCHAIN_MAINNET_SALES, window.ethereum.currentProvider),
    ICON: window.location.origin + '/networks/binance.png',
    API_URL: API_URL,
    GAS_LIMIT: [150000,200000,300000,400000,500000,600000,700000,800000,900000,1000000,1500000,2000000,3000000],
    PUBLIC: true,
    NATIVE_CURRENCY: { name: 'BNB', decimals: 18, symbol: 'BNB' },
    RPC_METAMASK_PROVIDER: 'https://bsc-dataseed.binance.org',
    ROUTES: DEFAULT_ROUTES,
    SUPPORTED_ASSETS: {},
    EXPLORER: 'https://bscscan.com'
};
  
export const QUORUM_BMARKET: IPrivateNetwork = {
    CHAIN_ID: '0x25',
    NAME: 'BMarket',
    AUCTIONS: PrivateAuctionsFactory.connect('0x451F1acf07668d9112814756E4df7372d651626C', window.ethereum.currentProvider),
    RENTS: PrivateRentsFactory.connect('0x040e7e0ffc2cD0955B4F762AeBb48aa510cB9313', window.ethereum.currentProvider),
    SALES: PrivateSalesFactory.connect('0xBA552aaBF7a8FcFa20882F64c30C002c5808f40E', window.ethereum.currentProvider),
    EXCHANGES: PrivateExchangesFactory.connect('0x09440b4cB43d64BE59131E0dCf76CB0806e766CF', window.ethereum.currentProvider),
    ITEMBETS: PrivateItemBetsFactory.connect('0x4D66533AFA31AA0356c31A7EceE2Ae59015FD53F', window.ethereum.currentProvider),
    LOTTERIES: PrivateLotteriesFactory.connect('0x91b13bb82E74A267712b7942033888c1cC75049E', window.ethereum.currentProvider),
    STORAGE: PrivateStorageFactory.connect('0x966aF8beDA2410aabeAa692c555c452e67A8B15d', window.ethereum.currentProvider),
    ICON: window.location.origin + '/networks/quorum.png',
    API_URL: API_URL,
    PUBLIC: false,
    NATIVE_CURRENCY: { name: 'ETH', decimals: 18, symbol: 'ETH' },
    RPC_METAMASK_PROVIDER: '',
    ROUTES: [
        ...DEFAULT_ROUTES,
        {
            NAME: "Vault",
            PATH: "/vault/list",
            CATEGORY: "vaults",
            navbarOption: true,
            subroutes: []
        },{
            NAME: "Exchanges",
            PATH: "/exchanges/list",
            CATEGORY: "exchanges",
            navbarOption: true,
            subroutes: [
                {
                    NAME: "Marketplace",
                    PATH: "/exchanges/list/marketplace",
                    personal: false
                },
                {
                    NAME: "Exchanged",
                    PATH: "/exchanges/list/exchanged",
                    personal: true
                }
            ]
        },{
            NAME: "Games",
            PATH: "/games/add",
            CATEGORY: "games",
            navbarOption: false,
            subroutes: []
        }
    ],
    SUPPORTED_ASSETS: {
        
    }
};

export interface INetworks {
    [key: string]: IPublicNetwork | IPrivateNetwork
}

export const NETWORKS: INetworks = {
    '0x5': ETHEREUM_GOERLI,
    '0x89': POLYGON_MAINNET,
    '0x13881': POLYGON_MUMBAI,
    '0x61': BINANCE_SMARTCHAIN_TESTNET,
    '0x38': BINANCE_SMARTCHAIN_MAINNET,
    '0x25': QUORUM_BMARKET
};


export const getNetwork = () => {
    const chainId: string | null = localStorage.getItem("chainId");
    return NETWORKS[chainId || QUORUM_BMARKET.CHAIN_ID];
}

export const DOCS: string = 'https://docs.' + DOMAIN;

export async function httpSimpleGetRequest(fullURL: string): Promise<any | undefined> {
    try {
        return await (await axios.get(fullURL)).data;
    } catch(err) {
        console.error(err);
        return undefined;
    }
}


export async function httpRequest(
    reqMethod: string,
    reqURL: string,
    reqBody: object
): Promise<any | undefined> {
    try {
        return await (await axios({ method : reqMethod, url : reqURL, data : reqBody })).data;
    } catch(err) {
        console.error(err);
        return undefined;
    }
}

export const steps = ['Select assets', 'Configure parameters', 'Confirm creation'];

export const formatImageUrl = (url: string): string => {
    let formattedUrl = url;
    if ( url.indexOf('ipfs://') !== -1 ) {
      formattedUrl = 'https://' + url.replaceAll('ipfs://','ipfs.io/ipfs/');
    }
    return formattedUrl;
}

export interface IMessages {
    METAMASK_ERROR: string;
}

export const messages: IMessages = {
    METAMASK_ERROR: "Transaction error! Please make sure your assets are valid and try again."
}