import { ethers } from "ethers";
import { NETWORKS } from "../../app/utility";

export interface SynchronizeRootStateConnectionParams {
    setAccounts?: Function;
    setSigner?: Function;
    setNetwork?: Function;
    setRefresher?: Function;
    networkId?: string;
    accounts?: Array<string>;
    signer?: ethers.providers.JsonRpcSigner;
}

export const synchronizeRootStateConnection = (
    params: SynchronizeRootStateConnectionParams
): void => {

    if ( params.setAccounts ) {
        params.setAccounts(params.accounts);
    }

    if ( params.setSigner ) {
        params.setSigner(params.signer);
    }

    if ( params.setNetwork && params.networkId ) {
        params.setNetwork(NETWORKS[params.networkId]);
        localStorage.setItem("chainId", params.networkId);
    }

    if ( params.setRefresher && params.networkId ) {
        params.setRefresher(new Date().getTime());
    }

    
}