import { useState, useEffect } from 'react';
import { ContractTransaction } from 'ethers';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { getCreationPrivateAssets } from '../../common/methods/getCreationPrivateAssets';
import { handlePrivateCreateTransactionReceipt } from '../../common/create/private/handlePrivateTransactionReceipt';
import { PrivateRoute } from '../../common/dto/routeProps';


export const Index = (props: PrivateRoute) => {
  const navigate: NavigateFunction = useNavigate();
  const [assets, setAssets] = useState<Array<any>>([]);
  const [receivedAssetsResponse, setReceivedAssetsResponse] = useState<boolean>(false);
  const [price, setPrice] = useState<string>('');
  const [tax, setTax] = useState<string>('');


  useEffect(() => {

    getCreationPrivateAssets({
      accounts: props.accounts,
      network: props.network,
      setAssets: setAssets,
      setError: props.setError,
      setRequestFinished: setReceivedAssetsResponse
    });

  }, []);


  const create = async () => {

    if ( Number(price) <= 0 ) {
      props.setError("Invalid price, must be greater than 0");
      setPrice('');
      return;
    }

    const tx: ContractTransaction = await props.network.AUCTIONS.createAuction(
      1, // game id
      1, // server id
      1, // item id
      1, // price
      1 // do add
    );

      await handlePrivateCreateTransactionReceipt({
        navigate: navigate,
        eventName: "NewAuction",
        setError: props.setError,
        tx: tx
      });


  }

  return (
    <>
    </>
  );
}
