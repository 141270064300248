import { IRouteProps } from '../../common/dto/routeProps';
import { Index as PrivateSaleView } from './private';
import { Index as PublicSaleView } from './public';

export default function Index(props: IRouteProps) {

    return (
        <>
            {
                props.network && 
                <>
                    {
                        props.network.PUBLIC ? (
                            <PublicSaleView {...props} />
                        ) : (
                            <PrivateSaleView {...props} />
                        )
                    }
                </>
            }
        </>
    );
}