import { formatImageUrl } from '../../../app/utility';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Grid from '@mui/material/Grid';


export interface PublicViewImage {
  url: string;
  name: string;
  description: string;
  owner: string;
  explorer: string;
  setCurrenAssetIndex: Function;
  totalItems: number;
  currentAssetIndex: number;
}

export const PublicViewImage = (props: PublicViewImage) => {
    return (
      <>
        {
            props.url && props.owner &&
            <>
              <img className='w-full' src={formatImageUrl(props.url)} alt={props.name}/>
              <Grid 
                container 
                wrap="nowrap"
                spacing={{ xs: 2, md: 3 }} 
                columns={{ xs: 4, sm: 8, md: 12 }}
                className='content-center text-center !mt-3 !mb-5'
              >
                <Grid item xs={2} sm={4} md={4}>
                  <IconButton onClick={()=> {
                      if ( props.currentAssetIndex === 0 ) {
                        props.setCurrenAssetIndex(props.totalItems - 1);
                      } else {
                        props.setCurrenAssetIndex(props.currentAssetIndex - 1);
                      }
                  }}>
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  {props.currentAssetIndex + 1} / {props.totalItems}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <IconButton onClick={()=> {
                    if ( props.currentAssetIndex === props.totalItems - 1) {
                      props.setCurrenAssetIndex(0);
                    } else {
                      props.setCurrenAssetIndex(props.currentAssetIndex + 1);
                    }
                  }}>
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <div className='px-6 py-4'>
                  <div className='font-bold text-xl mb-2'>{props.name}</div>
                  <p className='text-base'>
                      {props.description}
                  </p>
                  Owned by <a href={props.explorer + '/address/' + props.owner} target='blank' className='font-bold text-base'>{props.owner}</a>
              </div>
            </>
        }
      </>
    );
}