import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Theme } from '@mui/material';


export interface IPrivateCreatorCard {
  theme: Theme
}

export const AddGameCard = (props: IPrivateCreatorCard) => {
    const navigate = useNavigate();
    return (
      <a href="mailto:contact@tradyr.io">
        <div 
          className='!inline-grid !ml-4 !mr-4 !mb-4 !rounded-2xl !cursor-pointer shadow-xl content-start' 
          style={{ 
            width: '18rem',
            height: '18rem'
          }}
        >
          <img 
            src={'/creators/creategame.jpg'} 
            className='!h-72 !w-72 !rounded-2xl' 
          />
        </div>
      </a>
    )
}