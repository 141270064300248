import { ethers } from "ethers";
import { IPublicNetwork } from "../../app/utility";
import { RequiredApproval } from "../create/public/requiredApproval";
import { filterRequiredApprovals, generateRequiredApprovements, GenerateRequiredApprovementsOutput, solveFilteredRequiredApprovals } from "./handlePublicCreationApprovements";

export interface TriggerCreationPublicAssetsOutput {
    contractAddresses: Array<string>,
    assetIds: Array<string>,
    assetTypes: Array<number>,
    assetAmounts: Array<number>
}

export interface ITriggerCreationPublicAssetsParams {
    assets: Array<any>,
    network: IPublicNetwork,
    signer: ethers.providers.JsonRpcSigner
    setError: Function,
    setApprovals: Function,
    setSolvedApprovals: Function,
    contractAddressToApprove: string
}

export const triggerCreationPublicAssets = async (
    props: ITriggerCreationPublicAssetsParams
): Promise<TriggerCreationPublicAssetsOutput> => {

    let output: TriggerCreationPublicAssetsOutput = {
        assetIds: [],
        assetTypes: [],
        contractAddresses: [],
        assetAmounts: []
    };

    try {

        if ( props.assets.filter((asset: any) => asset.quantity > 0 ).length === 0 ) {
            props.setError("No assets selected");
            return output;
        }

        if ( props.network && props.signer ) {

            const generateRequiredApprovementsOutput: GenerateRequiredApprovementsOutput = await generateRequiredApprovements({
                assets: props.assets, 
                contractAddress: props.contractAddressToApprove,
                signer: props.signer
            });

            const filteredRequiredApprovalsOutput: Array<RequiredApproval> = await filterRequiredApprovals({
                requiredApprovals: generateRequiredApprovementsOutput.requiredApprovals,
                signer: props.signer,
                contractAddressToApprove: props.contractAddressToApprove
            });

            props.setApprovals(filteredRequiredApprovalsOutput);
            
            await solveFilteredRequiredApprovals({
                requiredApprovals: filteredRequiredApprovalsOutput,
                signer: props.signer,
                contractAddressToApprove: props.contractAddressToApprove,
                setSolvedApprovals: props.setSolvedApprovals
            });

            output = {
                assetIds: generateRequiredApprovementsOutput.assetIds,
                assetTypes: generateRequiredApprovementsOutput.assetTypes,
                contractAddresses: generateRequiredApprovementsOutput.contractAddresses,
                assetAmounts: generateRequiredApprovementsOutput.assetAmounts
            }

        }

    } catch (err: any) {

        console.error(Error(err).message);

    }

    return output;

}