import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { listOne } from '../../common/methods/listOne';
import { address0x0 } from '../../app/utility';
import { BigNumber, ContractReceipt, ContractTransaction } from 'ethers';
import { IPublicRentAsset } from '../../common/dto/assets/publicRentAsset';
import { PublicRoute } from '../../common/dto/routeProps';
import { PublicViewImage } from '../../common/cards/public/viewImage';
import { PublicViewStats } from '../../common/cards/public/viewStats';
import { Divider } from '@mui/material';
import { StatStyle } from '../../common/cards/public/statStyle';
import { ViewButton } from '../../common/buttons/viewButton';
import { triggerCreationPublicAssets } from '../../common/methods/triggerCreationPublicAssets';
import { generator } from '../../common/skeletons/generator';


export const Index = (props: PublicRoute) => {
    const [item, setItem] = useState<IPublicRentAsset>();
    const [currentAssetIndex, setCurrenAssetIndex] = useState(0);
    const { id } = useParams();

    useEffect(() => {
        try {
            const fetch = async () => {
                if ( id && props.network ) {
                    await listOne(
                        props.network.API_URL,
                        props.category,
                        props.network.CHAIN_ID,
                        id,
                        props.setError,
                        setItem
                    );
                }
            }
            fetch();
        } catch (err) {
            props.setError('Internal error, try again later!');
        }
    }, [id, props.network]);


    const cancel = async () => {
        try {
            if ( item && props.network ) {
                const tx: ContractTransaction = await props.network.RENTS
                .connect(props.signer)
                .cancel(BigNumber.from(item.tokenId));
                tx.wait().then((receipt: ContractReceipt) => {
                    setItem({
                        ...item,
                        status: 2
                    });
                    props.setSuccess("Rent has been successfully cancelled!");
                });
            }
        } catch (err) {
            console.error(err);
            props.setError("Failed to cancel rent");
        }
    }

    const finish = async () => {
        try {
            if ( item && props.network ) {

                await triggerCreationPublicAssets({
                    assets: item.items.map((item: any) => {
                        return {
                            ...item, 
                            quantity: 1,
                            contractType: item.type === 0 ? "ERC721" : "ERC1155",
                            tokenAddress: item.address,
                            tokenId: item.id
                        };
                    }),
                    network: props.network,
                    setApprovals: () => {},
                    setError: props.setError,
                    setSolvedApprovals: () => {},
                    signer: props.signer,
                    contractAddressToApprove: props.network.RENTS.address
                });

                const tx: ContractTransaction = await props.network.RENTS
                .connect(props.signer)
                .finish(BigNumber.from(item.tokenId),{
                    gasLimit: props.network.GAS_LIMIT[12]
                })
                tx.wait().then((receipt: ContractReceipt) => {
                    setItem({
                        ...item,
                        status: 0,
                        expiration: BigNumber.from('0').toHexString(),
                        client: address0x0
                    });
                    props.setSuccess("Rent has been successfully finished!");
                });

            }
        } catch (err) {
            console.error(err);
            props.setError("Failed to finish rent");
        }
    }

    const borrow = async () => {
        try {
            if ( item && props.network ) {
                const tx: ContractTransaction = await props.network.RENTS
                .connect(props.signer)
                .rent(BigNumber.from(item.tokenId),{
                    value: item.price,
                    gasLimit: props.network.GAS_LIMIT[12]
                });
                tx.wait().then((receipt: ContractReceipt) => {
                    setItem({
                        ...item,
                        status: 1,
                        expiration: BigNumber.from(new Date().getTime() + Number(item.valability)).toHexString(),
                        buyer: props.accounts[0],
                        client: props.accounts[0]
                    });
                    props.setSuccess("Rent assets have been successfully borrowed!");
                });
            }
        } catch (err) {
            console.error(err);
            props.setError("Failed to borrow assets");
        }
    }

    const buttons = (item: any) => {
        return <div className='grid grid-cols-1 divide-y'>
                    <div className='px-6 py-4'>
                        <div className='grid grid-cols-3 justify-content-center text-center'>
                            <div>
                                {
                                    item.owner && props.accounts[0] && 
                                    <>
                                        {
                                            item.owner.toLowerCase() === props.accounts[0].toLowerCase() && item.status === 0 && 
                                            <StatStyle 
                                                theme={props.theme}
                                                child={
                                                    <ViewButton
                                                        theme={props.theme}
                                                        label="Close"
                                                        onClick={cancel}
                                                    />
                                                }
                                            />
                                        }
                                    </>
                                }
                            </div>
                            <div>
                                {
                                    item.owner && props.accounts[0] && 
                                    <>
                                        {
                                            ( item.status === 0 ) && ( !item.client || ( item.client && item.client === address0x0 ) ) && item.owner.toLowerCase() !== props.accounts[0].toLowerCase() && 
                                            <StatStyle 
                                                theme={props.theme}
                                                child={
                                                    <ViewButton
                                                        theme={props.theme}
                                                        label="Borrow"
                                                        onClick={borrow}
                                                    />
                                                }
                                            />
                                        }
                                    </>
                                }
                            </div>
                            <div>
                                {
                                    item.client && props.accounts[0] && 
                                    <>
                                        {
                                            ( item.client.toLowerCase() === props.accounts[0].toLowerCase() || item.owner.toLowerCase() === props.accounts[0].toLowerCase() ) && item.status === 1 && 
                                            <StatStyle 
                                                theme={props.theme}
                                                child={
                                                    <ViewButton
                                                        theme={props.theme}
                                                        label="Finish"
                                                        onClick={finish}
                                                    />
                                                }
                                            />
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>;
    }

    return (
        <div className='p-10 flex justify-center items-center' style={{ marginTop: '180px' }}>
            {
                item ? (
                    <>
                        <div className='max-w-2xl rounded-3xl overflow-hidden !shadow-lg'>
                            <PublicViewImage 
                                url={item.items[0].metadata.metadata.image} 
                                explorer={props.network.EXPLORER}
                                description={item.items[0].metadata.metadata.description}
                                name={item.items[0].metadata.name}
                                owner={item.items[0].metadata.ownerOf} 
                                setCurrenAssetIndex={setCurrenAssetIndex}
                                totalItems={item.items.length}
                                currentAssetIndex={currentAssetIndex}
                            />
                            <PublicViewStats 
                                theme={props.theme}
                                price={item.price} 
                                currency={item.currency} 
                                nativeCurrency={props.network.NATIVE_CURRENCY}
                            />
                            <Divider/>
                            {buttons(item)}
                        </div>
                    </>
                ) : (
                    <div className='max-w-2xl rounded-3xl overflow-hidden !shadow-lg w-full w-100 h-100'>
                        {
                            generator(
                                1, 
                                props.theme, 
                                {
                                    'minWidth': '100%',
                                    'minHeight': '42rem'
                                }
                            )
                        }
                        {
                            generator(
                                1, 
                                props.theme, 
                                {
                                    'minWidth': '100%',
                                    'minHeight': '4rem'
                                }
                            )
                        }
                    </div>
                )
            }
        </div>
    );
}