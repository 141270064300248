import { ethers } from "ethers";
import { NETWORKS } from "../../app/utility";
import { changeNetwork } from "./changeNetwork";
import { synchronizeRootStateConnection } from './synchronizeRootStateConnection';

export interface ConnectionParams {
    provider: ethers.providers.Web3Provider,
    signer: ethers.providers.JsonRpcSigner,
    accounts: Array<string>,
    network: string
}

export interface MetamaskWuthParams {
    setAccounts: Function,
    setNetwork: Function,
    setRefresher: Function,
    setSigner: Function
}

export const metamaskAuth = async (
    props: MetamaskWuthParams
): Promise<ConnectionParams | undefined> => {
    try {

        if ( window.ethereum ) {

            const currentProvider: ethers.providers.Web3Provider = new ethers.providers.Web3Provider(window.ethereum, "any");
            const signer: ethers.providers.JsonRpcSigner = currentProvider.getSigner();
            const importedAccounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

            const chainId = Number(window.ethereum.chainId);
            const storedChainId: any = localStorage.getItem("chainId");

            let networkToUse: string;
            if ( storedChainId !== chainId && storedChainId > 0 ) {
                networkToUse = await changeNetwork(NETWORKS[storedChainId]);
            } else {
                networkToUse = await changeNetwork(NETWORKS[chainId]);
            }

            window.ethereum.on('accountsChanged', (accounts: Array<string>) => {
                synchronizeRootStateConnection({
                    setAccounts: props.setAccounts,
                    accounts: accounts
                });
            });
    
            window.ethereum.on('chainChanged', (chainId: string) => {
                synchronizeRootStateConnection({
                    setNetwork: props.setNetwork,
                    setRefresher: props.setRefresher,
                    networkId: chainId
                });
            });

            props.setAccounts(importedAccounts);
            props.setNetwork(NETWORKS[networkToUse]);
            props.setSigner(signer);

            return {
                provider: currentProvider,
                accounts: importedAccounts,
                signer: signer,
                network: networkToUse
            }

        }

        return;

    } catch (err: any) {
        console.error(Error(err).message);
    }
  }