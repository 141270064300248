import { Index as PublicSaleCreate } from "./public";
import { Index as PrivateSaleCreate } from "./private";
import { IRouteProps } from "../../common/dto/routeProps";


export default function Index(props: IRouteProps) {
  return (
    <>
      {
          props.network && 
          <>
              {
                  props.network.PUBLIC ? (
                    <PublicSaleCreate {...props} />
                  ) : (
                    <PrivateSaleCreate {...props} />
                  )
              }
          </>
      }
    </>
  );
}
