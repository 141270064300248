import { IPrivateNetwork, IPublicNetwork } from "../../app/utility";


export const changeNetwork = async (selectedNetwork: IPublicNetwork | IPrivateNetwork): Promise<string> => {
    if ( window.ethereum.networkVersion !== selectedNetwork.CHAIN_ID ) {
        if ( selectedNetwork.PUBLIC ) {
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: selectedNetwork.CHAIN_ID }]
                });
                return selectedNetwork.CHAIN_ID;
            } catch (err: any) {
                console.error(err);
                // This error code indicates that the chain has not been added to MetaMask
                if (err.code === 4902) {
                    await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: selectedNetwork.NAME,
                            chainId: selectedNetwork.CHAIN_ID,
                            nativeCurrency: selectedNetwork.NATIVE_CURRENCY,
                            rpcUrls: [selectedNetwork.RPC_METAMASK_PROVIDER]
                        }
                    ]
                    });
                    return selectedNetwork.CHAIN_ID;
                }
            }
        }
        return selectedNetwork.CHAIN_ID;
    }
    return selectedNetwork.CHAIN_ID;
  }